[
  {
    "matchId": "6622977560424",
    "timestamp": 1662684922,
    "timeAgo": {
      "number": 14,
      "unit": "days"
    },
    "clubs": {
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "0",
        "gfraw": "3",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "0",
        "opponentTeamArtAbbr": "DROP",
        "passa": "60",
        "passc": "40",
        "ppg": "1",
        "ppo": "1",
        "result": "16385",
        "score": "3",
        "scoreString": "3 - 0",
        "shots": "5",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "145",
        "winnerByDnf": "1",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "0"
      },
      "450721": {
        "clubDivision": "2",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "0",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "44",
        "passc": "33",
        "ppg": "0",
        "ppo": "0",
        "result": "10",
        "score": "0",
        "scoreString": "0 - 3",
        "shots": "6",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "178",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "0",
        "goalsAgainst": "3"
      }
    },
    "players": {
      "440740": {
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "1",
          "skpassattempts": "13",
          "skpasses": "7",
          "skpasspct": "53.85",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "163",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Sauc---y"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "90.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "7",
          "skfopct": "46.15",
          "skfow": "6",
          "skgiveaways": "4",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "11",
          "skpasses": "7",
          "skpasspct": "63.64",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "102",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "BarryWoodsDong"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "1.00",
          "glsaves": "6",
          "glshots": "6",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "186",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2397",
          "playername": "Seagull_3134"
        },
        "1135212727": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "160",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "6",
          "skpassattempts": "15",
          "skpasses": "12",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "111",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Mr_Janack12"
        },
        "1905054658": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "103",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "65.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "0",
          "skpassattempts": "8",
          "skpasses": "5",
          "skpasspct": "62.50",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "127",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "100.00",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "xTrxshy-_-"
        },
        "1975762195": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "75.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "13",
          "skpasses": "9",
          "skpasspct": "69.23",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "127",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Vaniskov"
        }
      },
      "450721": {
        "846525480": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "184",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "60.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "55.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "5",
          "skpasses": "2",
          "skpasspct": "40.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "93",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "alonzi401"
        },
        "1248857979": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "244",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "75.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "65.00",
          "score": "0",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "6",
          "skpassattempts": "10",
          "skpasses": "7",
          "skpasspct": "70.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "186",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "YuNgWuN413"
        },
        "1677503527": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "107",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "70.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "65.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "6",
          "skfopct": "53.85",
          "skfow": "7",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "4",
          "skpassattempts": "11",
          "skpasses": "9",
          "skpasspct": "81.82",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "132",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Toeffoli"
        },
        "1725195881": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "157",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "60.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "15.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "4",
          "skpassattempts": "1",
          "skpasses": "1",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "73",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "27",
          "toiseconds": "1592",
          "playername": "TuffLinkzz"
        },
        "1926645693": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "4.50",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.40",
          "glsaves": "2",
          "glshots": "5",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "129",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "50.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Rabb1tx14"
        },
        "1004931773441": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "237",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "70.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "60.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "16",
          "skpasses": "14",
          "skpasspct": "87.50",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "206",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Silverstxin"
        }
      }
    },
    "aggregate": {
      "440740": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 0,
        "glgaa": 0,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 1,
        "glsaves": 6,
        "glshots": 6,
        "glsoperiods": 0,
        "isGuest": 2,
        "opponentClubId": 2704326,
        "opponentScore": 0,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 696,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 440,
        "ratingOffense": 450,
        "ratingTeamplay": 355,
        "score": 18,
        "skassists": 6,
        "skbs": 1,
        "skdeflections": 0,
        "skfol": 7,
        "skfopct": 46.15,
        "skfow": 6,
        "skgiveaways": 29,
        "skgoals": 3,
        "skgwg": 0,
        "skhits": 5,
        "skinterceptions": 15,
        "skpassattempts": 60,
        "skpasses": 40,
        "skpasspct": 329.22,
        "skpenaltiesdrawn": 1,
        "skpim": 0,
        "skpkclearzone": 0,
        "skplusmin": 10,
        "skpossession": 630,
        "skppg": 1,
        "sksaucerpasses": 5,
        "skshg": 0,
        "skshotattempts": 6,
        "skshotonnetpct": 266.67,
        "skshotpct": 200,
        "skshots": 5,
        "sktakeaways": 10,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 240,
        "toiseconds": 14397
      },
      "450721": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 4.5,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.4,
        "glsaves": 2,
        "glshots": 5,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2644440,
        "opponentScore": 18,
        "opponentTeamId": 3000,
        "player_dnf": 2,
        "playerLevel": 1058,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 395,
        "ratingOffense": 330,
        "ratingTeamplay": 310,
        "score": 0,
        "skassists": 0,
        "skbs": 1,
        "skdeflections": 0,
        "skfol": 6,
        "skfopct": 53.85,
        "skfow": 7,
        "skgiveaways": 25,
        "skgoals": 0,
        "skgwg": 0,
        "skhits": 3,
        "skinterceptions": 18,
        "skpassattempts": 43,
        "skpasses": 33,
        "skpasspct": 379.32,
        "skpenaltiesdrawn": 0,
        "skpim": 2,
        "skpkclearzone": 0,
        "skplusmin": -10,
        "skpossession": 690,
        "skppg": 0,
        "sksaucerpasses": 1,
        "skshg": 0,
        "skshotattempts": 8,
        "skshotonnetpct": 216.67,
        "skshotpct": 0,
        "skshots": 6,
        "sktakeaways": 9,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 227,
        "toiseconds": 13592
      }
    }
  },
  {
    "matchId": "6666411490083",
    "timestamp": 1663116508,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "49842": {
        "clubDivision": "9",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "0",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "DROP",
        "passa": "22",
        "passc": "16",
        "ppg": "0",
        "ppo": "0",
        "result": "10",
        "score": "0",
        "scoreString": "0 - 3",
        "shots": "3",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "132",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Dynasty",
          "clubId": 49842,
          "regionId": 5,
          "teamId": 5152,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "778",
            "useBaseAsset": "1"
          }
        },
        "goals": "0",
        "goalsAgainst": "3"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "0",
        "gfraw": "3",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "49842",
        "opponentScore": "0",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "34",
        "passc": "24",
        "ppg": "0",
        "ppo": "0",
        "result": "16385",
        "score": "3",
        "scoreString": "3 - 0",
        "shots": "3",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "133",
        "winnerByDnf": "1",
        "winnerByGoalieDnf": "1",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "0"
      }
    },
    "players": {
      "49842": {
        "178739994": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "152",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "55.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "55.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "3",
          "skpasses": "2",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "37",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "rbobby1420"
        },
        "182593522": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "152",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "60.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "45.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "4",
          "skfopct": "33.33",
          "skfow": "2",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "6",
          "skpasses": "6",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "93",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "BOO_the_GHOST"
        },
        "1923423377": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "102",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "40.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "70.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "1",
          "skpassattempts": "4",
          "skpasses": "3",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "43",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "CGS_garbo_69"
        },
        "1975927092": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "9.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.25",
          "glsaves": "1",
          "glshots": "4",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "241",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "35.00",
          "ratingTeamplay": "50.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "v-l-e-x-y-y"
        },
        "1979501370": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "117",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "50.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "55.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "11",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "5",
          "skpassattempts": "6",
          "skpasses": "2",
          "skpasspct": "33.33",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "133",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "BenDuguay"
        },
        "1982919352": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "108",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "45.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "50.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "1",
          "skpasses": "1",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "4",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "10",
          "toiseconds": "595",
          "playername": "NoLuvFrostyx"
        }
      },
      "440740": {
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "55.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "6",
          "skpasses": "3",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "105",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "Sauc---y"
        },
        "655079392": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "1.00",
          "glsaves": "3",
          "glshots": "3",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "29",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "55.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "SVNickNitro"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "65.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "2",
          "skfopct": "66.67",
          "skfow": "4",
          "skgiveaways": "0",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "12",
          "skpasses": "8",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "101",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "200.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "BarryWoodsDong"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "60.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "2",
          "skpassattempts": "9",
          "skpasses": "6",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "72",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "Bilal-I96I"
        },
        "1905054658": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "103",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "60.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "4",
          "skpasses": "4",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "62",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "xTrxshy-_-"
        },
        "1975762195": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "55.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "3",
          "skpasses": "3",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "85",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "20",
          "toiseconds": "1200",
          "playername": "Vaniskov"
        }
      }
    },
    "aggregate": {
      "49842": {
        "class": 65,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 9,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.25,
        "glsaves": 1,
        "glshots": 4,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2644440,
        "opponentScore": 18,
        "opponentTeamId": 3006,
        "player_dnf": 2,
        "playerLevel": 872,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 310,
        "ratingOffense": 295,
        "ratingTeamplay": 325,
        "score": 0,
        "skassists": 0,
        "skbs": 0,
        "skdeflections": 0,
        "skfol": 4,
        "skfopct": 33.33,
        "skfow": 2,
        "skgiveaways": 17,
        "skgoals": 0,
        "skgwg": 0,
        "skhits": 6,
        "skinterceptions": 9,
        "skpassattempts": 20,
        "skpasses": 14,
        "skpasspct": 375,
        "skpenaltiesdrawn": 0,
        "skpim": 0,
        "skpkclearzone": 0,
        "skplusmin": -15,
        "skpossession": 310,
        "skppg": 0,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 5,
        "skshotonnetpct": 66.67,
        "skshotpct": 0,
        "skshots": 2,
        "sktakeaways": 7,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 110,
        "toiseconds": 6595
      },
      "440740": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 0,
        "glgaa": 0,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 1,
        "glsaves": 3,
        "glshots": 3,
        "glsoperiods": 0,
        "isGuest": 2,
        "opponentClubId": 299052,
        "opponentScore": 0,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 581,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 350,
        "ratingOffense": 400,
        "ratingTeamplay": 335,
        "score": 18,
        "skassists": 4,
        "skbs": 2,
        "skdeflections": 0,
        "skfol": 2,
        "skfopct": 66.67,
        "skfow": 4,
        "skgiveaways": 19,
        "skgoals": 3,
        "skgwg": 0,
        "skhits": 3,
        "skinterceptions": 12,
        "skpassattempts": 34,
        "skpasses": 24,
        "skpasspct": 383.34,
        "skpenaltiesdrawn": 0,
        "skpim": 0,
        "skpkclearzone": 0,
        "skplusmin": 15,
        "skpossession": 425,
        "skppg": 0,
        "sksaucerpasses": 1,
        "skshg": 0,
        "skshotattempts": 4,
        "skshotonnetpct": 250,
        "skshotpct": 300,
        "skshots": 3,
        "sktakeaways": 5,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 120,
        "toiseconds": 7200
      }
    }
  },
  {
    "matchId": "6670280930036",
    "timestamp": 1663117892,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "49842": {
        "clubDivision": "9",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "4",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "64",
        "passc": "39",
        "ppg": "0",
        "ppo": "1",
        "result": "10",
        "score": "0",
        "scoreString": "0 - 3",
        "shots": "14",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "264",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Dynasty",
          "clubId": 49842,
          "regionId": 5,
          "teamId": 5152,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "778",
            "useBaseAsset": "1"
          }
        },
        "goals": "0",
        "goalsAgainst": "3"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "4",
        "gfraw": "3",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "49842",
        "opponentScore": "0",
        "opponentTeamArtAbbr": "DROP",
        "passa": "48",
        "passc": "30",
        "ppg": "0",
        "ppo": "2",
        "result": "16385",
        "score": "3",
        "scoreString": "3 - 0",
        "shots": "7",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "181",
        "winnerByDnf": "1",
        "winnerByGoalieDnf": "1",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "0"
      }
    },
    "players": {
      "49842": {
        "178739994": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "152",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "65.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "55.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "6",
          "skpasses": "3",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "69",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "rbobby1420"
        },
        "182593522": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "152",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "60.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "65.00",
          "score": "0",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "7",
          "skfopct": "69.57",
          "skfow": "16",
          "skgiveaways": "4",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "9",
          "skpassattempts": "17",
          "skpasses": "11",
          "skpasspct": "64.71",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "146",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "4",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "BOO_the_GHOST"
        },
        "1923423377": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "102",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "55.00",
          "score": "0",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "6",
          "skpassattempts": "18",
          "skpasses": "10",
          "skpasspct": "55.56",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "2",
          "skplusmin": "1",
          "skpossession": "139",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "38",
          "toiseconds": "2281",
          "playername": "CGS_garbo_69"
        },
        "1975927092": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "4.50",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.57",
          "glsaves": "4",
          "glshots": "7",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "241",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "45.00",
          "ratingTeamplay": "50.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "v-l-e-x-y-y"
        },
        "1979501370": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "117",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "65.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "60.00",
          "score": "0",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "6",
          "skpassattempts": "11",
          "skpasses": "5",
          "skpasspct": "45.45",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "171",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "60.00",
          "skshotpct": "66.67",
          "skshots": "3",
          "sktakeaways": "6",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "BenDuguay"
        },
        "1982919352": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "108",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "45.00",
          "score": "0",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "3",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "12",
          "skpasses": "10",
          "skpasspct": "83.33",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "122",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "33.33",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "4",
          "teamId": "501",
          "teamSide": "1",
          "toi": "38",
          "toiseconds": "2275",
          "playername": "NoLuvFrostyx"
        }
      },
      "440740": {
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "6",
          "skpassattempts": "12",
          "skpasses": "6",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "134",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "38",
          "toiseconds": "2280",
          "playername": "Sauc---y"
        },
        "655079392": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "4",
          "glgaa": "6.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.71",
          "glsaves": "10",
          "glshots": "14",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "29",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "35.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "SVNickNitro"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "65.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "16",
          "skfopct": "30.43",
          "skfow": "7",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "9",
          "skpasses": "5",
          "skpasspct": "55.56",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "111",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "BarryWoodsDong"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "60.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "85.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "8",
          "skinterceptions": "8",
          "skpassattempts": "16",
          "skpasses": "11",
          "skpasspct": "68.75",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "204",
          "skppg": "0",
          "sksaucerpasses": "4",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Bilal-I96I"
        },
        "1905054658": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "103",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "40.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "4",
          "skpasses": "4",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "85",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "80.00",
          "skshotpct": "50.00",
          "skshots": "4",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "xTrxshy-_-"
        },
        "1975762195": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "40.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "7",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "6",
          "skpasses": "4",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "99",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "1",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Vaniskov"
        }
      }
    },
    "aggregate": {
      "49842": {
        "class": 65,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 4.5,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.57,
        "glsaves": 4,
        "glshots": 7,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2644440,
        "opponentScore": 18,
        "opponentTeamId": 3000,
        "player_dnf": 3,
        "playerLevel": 872,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 385,
        "ratingOffense": 435,
        "ratingTeamplay": 330,
        "score": 0,
        "skassists": 5,
        "skbs": 1,
        "skdeflections": 0,
        "skfol": 7,
        "skfopct": 69.57,
        "skfow": 16,
        "skgiveaways": 25,
        "skgoals": 4,
        "skgwg": 0,
        "skhits": 6,
        "skinterceptions": 26,
        "skpassattempts": 64,
        "skpasses": 39,
        "skpasspct": 299.05,
        "skpenaltiesdrawn": 1,
        "skpim": 4,
        "skpkclearzone": 3,
        "skplusmin": 5,
        "skpossession": 647,
        "skppg": 0,
        "sksaucerpasses": 2,
        "skshg": 0,
        "skshotattempts": 15,
        "skshotonnetpct": 360,
        "skshotpct": 200,
        "skshots": 10,
        "sktakeaways": 17,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 236,
        "toiseconds": 14156
      },
      "440740": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 4,
        "glgaa": 6,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.71,
        "glsaves": 10,
        "glshots": 14,
        "glsoperiods": 0,
        "isGuest": 2,
        "opponentClubId": 299052,
        "opponentScore": 0,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 581,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 340,
        "ratingOffense": 395,
        "ratingTeamplay": 365,
        "score": 18,
        "skassists": 4,
        "skbs": 3,
        "skdeflections": 0,
        "skfol": 16,
        "skfopct": 30.43,
        "skfow": 7,
        "skgiveaways": 43,
        "skgoals": 3,
        "skgwg": 0,
        "skhits": 14,
        "skinterceptions": 18,
        "skpassattempts": 47,
        "skpasses": 30,
        "skpasspct": 340.98,
        "skpenaltiesdrawn": 1,
        "skpim": 2,
        "skpkclearzone": 1,
        "skplusmin": -6,
        "skpossession": 633,
        "skppg": 0,
        "sksaucerpasses": 8,
        "skshg": 1,
        "skshotattempts": 10,
        "skshotonnetpct": 155,
        "skshotpct": 83.33,
        "skshots": 7,
        "sktakeaways": 7,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 238,
        "toiseconds": 14280
      }
    }
  },
  {
    "matchId": "6670344810136",
    "timestamp": 1663120434,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "49842": {
        "clubDivision": "9",
        "cNhlOnlineGameType": "5",
        "garaw": "2",
        "gfraw": "0",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "DROP",
        "passa": "32",
        "passc": "21",
        "ppg": "0",
        "ppo": "2",
        "result": "10",
        "score": "0",
        "scoreString": "0 - 3",
        "shots": "3",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "116",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Dynasty",
          "clubId": 49842,
          "regionId": 5,
          "teamId": 5152,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "778",
            "useBaseAsset": "1"
          }
        },
        "goals": "0",
        "goalsAgainst": "3"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "0",
        "gfraw": "2",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "49842",
        "opponentScore": "0",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "38",
        "passc": "28",
        "ppg": "1",
        "ppo": "3",
        "result": "16385",
        "score": "3",
        "scoreString": "3 - 0",
        "shots": "5",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "96",
        "winnerByDnf": "1",
        "winnerByGoalieDnf": "1",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "0"
      }
    },
    "players": {
      "49842": {
        "178739994": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "152",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "50.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "40.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "4",
          "skpassattempts": "5",
          "skpasses": "5",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "61",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "23",
          "toiseconds": "1352",
          "playername": "rbobby1420"
        },
        "1311299550": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "166",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "60.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "60.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "5",
          "skfopct": "58.33",
          "skfow": "7",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "10",
          "skpasses": "3",
          "skpasspct": "30.00",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "79",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "22",
          "toiseconds": "1349",
          "playername": "zZubee-"
        },
        "1923423377": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "102",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "65.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "45.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "5",
          "skpassattempts": "4",
          "skpasses": "4",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "2",
          "skplusmin": "-1",
          "skpossession": "45",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "21",
          "toiseconds": "1233",
          "playername": "CGS_garbo_69"
        },
        "1975927092": {
          "class": "21",
          "glbrksavepct": "0.50",
          "glbrksaves": "1",
          "glbrkshots": "2",
          "gldsaves": "0",
          "glga": "2",
          "glgaa": "5.22",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.60",
          "glsaves": "3",
          "glshots": "5",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "241",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "50.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "23",
          "toiseconds": "1352",
          "playername": "v-l-e-x-y-y"
        },
        "1979501370": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "117",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "65.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "0.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "4",
          "skpassattempts": "10",
          "skpasses": "7",
          "skpasspct": "70.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "126",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "22",
          "toiseconds": "1322",
          "playername": "BenDuguay"
        },
        "1982919352": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "108",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "45.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "45.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "1",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "3",
          "skpasses": "2",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "68",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "21",
          "toiseconds": "1233",
          "playername": "NoLuvFrostyx"
        }
      },
      "440740": {
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "3",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "10",
          "skpasses": "8",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "54",
          "skppg": "1",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "23",
          "toiseconds": "1352",
          "playername": "Sauc---y"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "60.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "30.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "5",
          "skfopct": "50.00",
          "skfow": "5",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "2",
          "skpassattempts": "11",
          "skpasses": "6",
          "skpasspct": "54.55",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "85",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "21",
          "toiseconds": "1233",
          "playername": "BarryWoodsDong"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "1.00",
          "glsaves": "3",
          "glshots": "3",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "187",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "55.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "23",
          "toiseconds": "1352",
          "playername": "Seagull_3134"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "65.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "1",
          "skpassattempts": "8",
          "skpasses": "6",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "83",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "21",
          "toiseconds": "1233",
          "playername": "Bilal-I96I"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "65.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "20.00",
          "score": "3",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "4",
          "skpasses": "4",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "48",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "1",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "23",
          "toiseconds": "1352",
          "playername": "A-H-O_20-"
        },
        "1004704581653": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "251",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "25.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "2",
          "skfopct": "33.33",
          "skfow": "1",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "5",
          "skpasses": "4",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "2",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "68",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "23",
          "toiseconds": "1352",
          "playername": "xBxllxgxrde-"
        }
      }
    },
    "aggregate": {
      "49842": {
        "class": 65,
        "glbrksavepct": 0.5,
        "glbrksaves": 1,
        "glbrkshots": 2,
        "gldsaves": 0,
        "glga": 2,
        "glgaa": 5.22,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.6,
        "glsaves": 3,
        "glshots": 5,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2644440,
        "opponentScore": 18,
        "opponentTeamId": 3006,
        "player_dnf": 3,
        "playerLevel": 886,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 345,
        "ratingOffense": 325,
        "ratingTeamplay": 240,
        "score": 0,
        "skassists": 0,
        "skbs": 0,
        "skdeflections": 0,
        "skfol": 6,
        "skfopct": 58.33,
        "skfow": 7,
        "skgiveaways": 12,
        "skgoals": 0,
        "skgwg": 0,
        "skhits": 3,
        "skinterceptions": 16,
        "skpassattempts": 32,
        "skpasses": 21,
        "skpasspct": 366.67,
        "skpenaltiesdrawn": 2,
        "skpim": 8,
        "skpkclearzone": 2,
        "skplusmin": -5,
        "skpossession": 379,
        "skppg": 0,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 4,
        "skshotonnetpct": 150,
        "skshotpct": 0,
        "skshots": 2,
        "sktakeaways": 8,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 132,
        "toiseconds": 7841
      },
      "440740": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 0,
        "glgaa": 0,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 1,
        "glsaves": 3,
        "glshots": 3,
        "glsoperiods": 0,
        "isGuest": 3,
        "opponentClubId": 299052,
        "opponentScore": 0,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1031,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 380,
        "ratingOffense": 380,
        "ratingTeamplay": 230,
        "score": 18,
        "skassists": 2,
        "skbs": 1,
        "skdeflections": 0,
        "skfol": 7,
        "skfopct": 83.33,
        "skfow": 6,
        "skgiveaways": 24,
        "skgoals": 2,
        "skgwg": 0,
        "skhits": 5,
        "skinterceptions": 7,
        "skpassattempts": 38,
        "skpasses": 28,
        "skpasspct": 389.55,
        "skpenaltiesdrawn": 3,
        "skpim": 4,
        "skpkclearzone": 0,
        "skplusmin": 3,
        "skpossession": 338,
        "skppg": 1,
        "sksaucerpasses": 7,
        "skshg": 1,
        "skshotattempts": 5,
        "skshotonnetpct": 300,
        "skshotpct": 133.33,
        "skshots": 5,
        "sktakeaways": 5,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 134,
        "toiseconds": 7874
      }
    }
  },
  {
    "matchId": "6674065250161",
    "timestamp": 1663119993,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "415948": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "4",
        "gfraw": "1",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "416581",
        "opponentScore": "4",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "84",
        "passc": "58",
        "ppg": "0",
        "ppo": "5",
        "result": "2",
        "score": "1",
        "scoreString": "1 - 4",
        "shots": "10",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "193",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Ontario Wolvess",
          "clubId": 415948,
          "regionId": 5,
          "teamId": 5158,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "91",
            "useBaseAsset": "1"
          }
        },
        "goals": "1",
        "goalsAgainst": "4"
      },
      "416581": {
        "clubDivision": "5",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "4",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "415948",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "DROP",
        "passa": "88",
        "passc": "62",
        "ppg": "2",
        "ppo": "5",
        "result": "1",
        "score": "4",
        "scoreString": "4 - 1",
        "shots": "10",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "470",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Screaming Eagles",
          "clubId": 416581,
          "regionId": 5,
          "teamId": 5171,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "233",
            "useBaseAsset": "1"
          }
        },
        "goals": "4",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "415948": {
        "218847382": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "199",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "7",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "3",
          "skpassattempts": "18",
          "skpasses": "14",
          "skpasspct": "77.78",
          "skpenaltiesdrawn": "1",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "163",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "57",
          "toiseconds": "3413",
          "playername": "Sub-System"
        },
        "1692118818": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "205",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "95.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "3",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "7",
          "skpassattempts": "13",
          "skpasses": "10",
          "skpasspct": "76.92",
          "skpenaltiesdrawn": "2",
          "skpim": "2",
          "skpkclearzone": "2",
          "skplusmin": "-1",
          "skpossession": "125",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "5",
          "teamId": "501",
          "teamSide": "1",
          "toi": "59",
          "toiseconds": "3527",
          "playername": "Madaia23"
        },
        "1723809220": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "4",
          "glgaa": "4.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.64",
          "glsaves": "7",
          "glshots": "11",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "87",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "40.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "tml_duff"
        },
        "1777185185": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "212",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "90.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "60.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "15",
          "skpassattempts": "27",
          "skpasses": "19",
          "skpasspct": "70.37",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "173",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "8",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "J-Bauw"
        },
        "1985034943": {
          "class": "3",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "263",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "70.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "55.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "18",
          "skfopct": "25.00",
          "skfow": "6",
          "skgiveaways": "9",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "9",
          "skinterceptions": "4",
          "skpassattempts": "16",
          "skpasses": "9",
          "skpasspct": "56.25",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "122",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "62.50",
          "skshotpct": "20.00",
          "skshots": "5",
          "sktakeaways": "4",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3595",
          "playername": "silky_johnsson22"
        },
        "1005690788601": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "205",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "70.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "70.00",
          "score": "1",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "1",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "5",
          "skpassattempts": "8",
          "skpasses": "5",
          "skpasspct": "62.50",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "115",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "EAIsSoftIMBACKBB"
        }
      },
      "416581": {
        "478857542": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "129",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "60.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "40.00",
          "score": "4",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "100.00",
          "skfow": "2",
          "skgiveaways": "4",
          "skgoals": "3",
          "skgwg": "1",
          "skhits": "1",
          "skinterceptions": "5",
          "skpassattempts": "9",
          "skpasses": "6",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "1",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "128",
          "skppg": "2",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "62.50",
          "skshotpct": "60.00",
          "skshots": "5",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "56",
          "toiseconds": "3361",
          "playername": "xCxshy"
        },
        "665487349": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "1.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.90",
          "glsaves": "9",
          "glshots": "10",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "142",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "65.00",
          "score": "4",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "MrBlondeMedia"
        },
        "1021379901": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "192",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "90.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "70.00",
          "score": "4",
          "skassists": "1",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "15",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "5",
          "skpassattempts": "18",
          "skpasses": "8",
          "skpasspct": "44.44",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "201",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Ryy2Flyy-"
        },
        "1705605309": {
          "class": "14",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "75.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "75.00",
          "score": "4",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "11",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "5",
          "skpassattempts": "27",
          "skpasses": "22",
          "skpasspct": "81.48",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "175",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Bxrtzyy-_-"
        },
        "1752079379": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "90",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "60.00",
          "score": "4",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "16",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "5",
          "skpassattempts": "14",
          "skpasses": "10",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "2",
          "skpim": "4",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "342",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "33.33",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "56",
          "toiseconds": "3350",
          "playername": "iamsmokxy-_-"
        },
        "1003684003789": {
          "class": "3",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "207",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "75.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "80.00",
          "score": "4",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "6",
          "skfopct": "73.91",
          "skfow": "17",
          "skgiveaways": "10",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "2",
          "skpassattempts": "17",
          "skpasses": "13",
          "skpasspct": "76.47",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "2",
          "skplusmin": "1",
          "skpossession": "297",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "60.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "Hxiskanen"
        }
      }
    },
    "aggregate": {
      "415948": {
        "class": 71,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 4,
        "glgaa": 4,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.64,
        "glsaves": 7,
        "glshots": 11,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2499486,
        "opponentScore": 24,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1171,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 470,
        "ratingOffense": 360,
        "ratingTeamplay": 340,
        "score": 6,
        "skassists": 2,
        "skbs": 1,
        "skdeflections": 0,
        "skfol": 19,
        "skfopct": 25,
        "skfow": 6,
        "skgiveaways": 30,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 21,
        "skinterceptions": 34,
        "skpassattempts": 82,
        "skpasses": 57,
        "skpasspct": 343.82,
        "skpenaltiesdrawn": 5,
        "skpim": 10,
        "skpkclearzone": 3,
        "skplusmin": -5,
        "skpossession": 698,
        "skppg": 0,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 16,
        "skshotonnetpct": 279.17,
        "skshotpct": 20,
        "skshots": 9,
        "sktakeaways": 22,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 354,
        "toiseconds": 21216
      },
      "416581": {
        "class": 57,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 1,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.9,
        "glsaves": 9,
        "glshots": 10,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2495688,
        "opponentScore": 6,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 948,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 440,
        "ratingOffense": 455,
        "ratingTeamplay": 390,
        "score": 24,
        "skassists": 5,
        "skbs": 4,
        "skdeflections": 0,
        "skfol": 6,
        "skfopct": 173.91,
        "skfow": 19,
        "skgiveaways": 56,
        "skgoals": 4,
        "skgwg": 1,
        "skhits": 14,
        "skinterceptions": 22,
        "skpassattempts": 85,
        "skpasses": 59,
        "skpasspct": 340.49,
        "skpenaltiesdrawn": 5,
        "skpim": 10,
        "skpkclearzone": 4,
        "skplusmin": 5,
        "skpossession": 1143,
        "skppg": 2,
        "sksaucerpasses": 4,
        "skshg": 0,
        "skshotattempts": 20,
        "skshotonnetpct": 155.83,
        "skshotpct": 93.33,
        "skshots": 9,
        "sktakeaways": 8,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 350,
        "toiseconds": 20992
      }
    }
  },
  {
    "matchId": "6674072270391",
    "timestamp": 1663119527,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "49842": {
        "clubDivision": "9",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "2",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "DROP",
        "passa": "45",
        "passc": "29",
        "ppg": "2",
        "ppo": "3",
        "result": "10",
        "score": "0",
        "scoreString": "0 - 3",
        "shots": "8",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "249",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Dynasty",
          "clubId": 49842,
          "regionId": 5,
          "teamId": 5152,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "778",
            "useBaseAsset": "1"
          }
        },
        "goals": "0",
        "goalsAgainst": "3"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "2",
        "gfraw": "1",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "49842",
        "opponentScore": "0",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "77",
        "passc": "57",
        "ppg": "0",
        "ppo": "1",
        "result": "16385",
        "score": "3",
        "scoreString": "3 - 0",
        "shots": "12",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "263",
        "winnerByDnf": "1",
        "winnerByGoalieDnf": "1",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "0"
      }
    },
    "players": {
      "49842": {
        "178739994": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "152",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "70.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "55.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "4",
          "skpasses": "4",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "29",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "rbobby1420"
        },
        "1311299550": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "166",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "80.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "65.00",
          "score": "0",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "8",
          "skfopct": "55.56",
          "skfow": "10",
          "skgiveaways": "2",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "4",
          "skpassattempts": "15",
          "skpasses": "10",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "138",
          "skppg": "1",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "zZubee-"
        },
        "1923423377": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "102",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "85.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "75.00",
          "score": "0",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "4",
          "skpassattempts": "8",
          "skpasses": "6",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "72",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "CGS_garbo_69"
        },
        "1975927092": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "1.50",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.92",
          "glsaves": "11",
          "glshots": "12",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "241",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "50.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "v-l-e-x-y-y"
        },
        "1979501370": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "117",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "70.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "55.00",
          "score": "0",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "8",
          "skpassattempts": "10",
          "skpasses": "3",
          "skpasspct": "30.00",
          "skpenaltiesdrawn": "2",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "233",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "6",
          "teamId": "500",
          "teamSide": "0",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "BenDuguay"
        },
        "1982919352": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "108",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "55.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "35.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "5",
          "skpasses": "4",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "74",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "19",
          "toiseconds": "1151",
          "playername": "NoLuvFrostyx"
        }
      },
      "440740": {
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "90.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "13",
          "skpasses": "9",
          "skpasspct": "69.23",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "105",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Sauc---y"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "80.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "30.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "7",
          "skfopct": "46.15",
          "skfow": "6",
          "skgiveaways": "5",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "14",
          "skpasses": "8",
          "skpasspct": "57.14",
          "skpenaltiesdrawn": "0",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "109",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "37",
          "toiseconds": "2214",
          "playername": "BarryWoodsDong"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "2",
          "glgaa": "3.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.75",
          "glsaves": "6",
          "glshots": "8",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "187",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "45.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "Seagull_3134"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "40.00",
          "score": "3",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "5",
          "skpassattempts": "21",
          "skpasses": "15",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "240",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "4",
          "teamId": "501",
          "teamSide": "1",
          "toi": "39",
          "toiseconds": "2325",
          "playername": "Bilal-I96I"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "55.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "15",
          "skpasses": "13",
          "skpasspct": "86.67",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "131",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "60.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "A-H-O_20-"
        },
        "1004704581653": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "49842",
          "opponentScore": "0",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "251",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "80.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "55.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "3",
          "skfopct": "40.00",
          "skfow": "2",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "12",
          "skpasses": "10",
          "skpasspct": "83.33",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "161",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "4",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "40",
          "toiseconds": "2400",
          "playername": "xBxllxgxrde-"
        }
      }
    },
    "aggregate": {
      "49842": {
        "class": 65,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 1.5,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.92,
        "glsaves": 11,
        "glshots": 12,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2644440,
        "opponentScore": 18,
        "opponentTeamId": 3006,
        "player_dnf": 2,
        "playerLevel": 886,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 430,
        "ratingOffense": 385,
        "ratingTeamplay": 335,
        "score": 0,
        "skassists": 2,
        "skbs": 3,
        "skdeflections": 0,
        "skfol": 8,
        "skfopct": 55.56,
        "skfow": 10,
        "skgiveaways": 23,
        "skgoals": 2,
        "skgwg": 0,
        "skhits": 5,
        "skinterceptions": 18,
        "skpassattempts": 42,
        "skpasses": 27,
        "skpasspct": 351.67,
        "skpenaltiesdrawn": 3,
        "skpim": 2,
        "skpkclearzone": 1,
        "skplusmin": -5,
        "skpossession": 546,
        "skppg": 2,
        "sksaucerpasses": 2,
        "skshg": 0,
        "skshotattempts": 11,
        "skshotonnetpct": 241.67,
        "skshotpct": 83.33,
        "skshots": 6,
        "sktakeaways": 14,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 219,
        "toiseconds": 13151
      },
      "440740": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 2,
        "glgaa": 3,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.75,
        "glsaves": 6,
        "glshots": 8,
        "glsoperiods": 0,
        "isGuest": 3,
        "opponentClubId": 299052,
        "opponentScore": 0,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1031,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 445,
        "ratingOffense": 370,
        "ratingTeamplay": 295,
        "score": 18,
        "skassists": 1,
        "skbs": 5,
        "skdeflections": 0,
        "skfol": 10,
        "skfopct": 86.15,
        "skfow": 8,
        "skgiveaways": 33,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 2,
        "skinterceptions": 13,
        "skpassattempts": 75,
        "skpasses": 55,
        "skpasspct": 367.8,
        "skpenaltiesdrawn": 1,
        "skpim": 6,
        "skpkclearzone": 0,
        "skplusmin": 5,
        "skpossession": 746,
        "skppg": 0,
        "sksaucerpasses": 2,
        "skshg": 0,
        "skshotattempts": 14,
        "skshotonnetpct": 276.67,
        "skshotpct": 50,
        "skshots": 10,
        "sktakeaways": 10,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 236,
        "toiseconds": 14139
      }
    }
  },
  {
    "matchId": "6676877400109",
    "timestamp": 1663206712,
    "timeAgo": {
      "number": 21,
      "unit": "hours"
    },
    "clubs": {
      "415948": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "6",
        "gfraw": "1",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "416581",
        "opponentScore": "6",
        "opponentTeamArtAbbr": "DROP",
        "passa": "64",
        "passc": "42",
        "ppg": "0",
        "ppo": "2",
        "result": "2",
        "score": "1",
        "scoreString": "1 - 6",
        "shots": "6",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "124",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Ontario Wolvess",
          "clubId": 415948,
          "regionId": 5,
          "teamId": 5158,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "91",
            "useBaseAsset": "1"
          }
        },
        "goals": "1",
        "goalsAgainst": "6"
      },
      "416581": {
        "clubDivision": "5",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "6",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "415948",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "88",
        "passc": "65",
        "ppg": "2",
        "ppo": "6",
        "result": "1",
        "score": "6",
        "scoreString": "6 - 1",
        "shots": "29",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "686",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Screaming Eagles",
          "clubId": 416581,
          "regionId": 5,
          "teamId": 5171,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "233",
            "useBaseAsset": "1"
          }
        },
        "goals": "6",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "415948": {
        "268730339": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "6",
          "glgaa": "6.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.80",
          "glsaves": "24",
          "glshots": "30",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "85.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Rangerfan142"
        },
        "885391995": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "111",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "65.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "55.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "9",
          "skinterceptions": "2",
          "skpassattempts": "18",
          "skpasses": "12",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "9",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "128",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "52",
          "toiseconds": "3144",
          "playername": "JoeK0325"
        },
        "1240123792": {
          "class": "5",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "76",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "85.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "95.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "3",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "12",
          "skinterceptions": "6",
          "skpassattempts": "10",
          "skpasses": "6",
          "skpasspct": "60.00",
          "skpenaltiesdrawn": "1",
          "skpim": "14",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "62",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "49",
          "toiseconds": "2961",
          "playername": "BlazedNBroke420"
        },
        "1855917359": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "175",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "65.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "25",
          "skfopct": "21.88",
          "skfow": "7",
          "skgiveaways": "9",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "5",
          "skpassattempts": "16",
          "skpasses": "9",
          "skpasspct": "56.25",
          "skpenaltiesdrawn": "3",
          "skpim": "10",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "118",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "5",
          "teamId": "500",
          "teamSide": "0",
          "toi": "55",
          "toiseconds": "3290",
          "playername": "ThatGuyCleary56"
        },
        "1985034943": {
          "class": "3",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "263",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "55.00",
          "score": "1",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "2",
          "skfopct": "50.00",
          "skfow": "2",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "5",
          "skpassattempts": "10",
          "skpasses": "7",
          "skpasspct": "70.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-3",
          "skpossession": "168",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "5",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "silky_johnsson22"
        },
        "1005164253946": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "24",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "55.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "40.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "8",
          "skpassattempts": "6",
          "skpasses": "4",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "132",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Ranchero215"
        }
      },
      "416581": {
        "182377262": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "288",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "85.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "80.00",
          "score": "6",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "6",
          "skinterceptions": "10",
          "skpassattempts": "12",
          "skpasses": "8",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "3",
          "skpossession": "216",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BigPapaPumpkin"
        },
        "384513004": {
          "class": "3",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "232",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "60.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "70.00",
          "score": "6",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "2",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "0",
          "skpassattempts": "12",
          "skpasses": "7",
          "skpasspct": "58.33",
          "skpenaltiesdrawn": "2",
          "skpim": "24",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "195",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "116.67",
          "skshotpct": "28.57",
          "skshots": "7",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "45",
          "toiseconds": "2711",
          "playername": "Sweht_-"
        },
        "1021379901": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "192",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "80.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "85.00",
          "score": "6",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "0",
          "skpassattempts": "9",
          "skpasses": "5",
          "skpasspct": "55.56",
          "skpenaltiesdrawn": "3",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "211",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "71.43",
          "skshotpct": "20.00",
          "skshots": "5",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Ryy2Flyy-"
        },
        "1780423381": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "90.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "100.00",
          "score": "6",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "9",
          "skfopct": "75.68",
          "skfow": "28",
          "skgiveaways": "10",
          "skgoals": "3",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "18",
          "skpasses": "15",
          "skpasspct": "83.33",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "299",
          "skppg": "2",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "10",
          "skshotonnetpct": "90.00",
          "skshotpct": "33.33",
          "skshots": "9",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "xDynamicEntryx"
        },
        "1927694899": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "191",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "80.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "65.00",
          "score": "6",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "8",
          "skpassattempts": "28",
          "skpasses": "23",
          "skpasspct": "82.14",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "3",
          "skpossession": "371",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "EvanK50"
        },
        "1999109791": {
          "class": "21",
          "glbrksavepct": "1.00",
          "glbrksaves": "1",
          "glbrkshots": "1",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "1.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.83",
          "glsaves": "5",
          "glshots": "6",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "127",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "60.00",
          "score": "6",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Machete-Joe-"
        }
      }
    },
    "aggregate": {
      "415948": {
        "class": 44,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 6,
        "glgaa": 6,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.8,
        "glsaves": 24,
        "glshots": 30,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2499486,
        "opponentScore": 36,
        "opponentTeamId": 3006,
        "player_dnf": 1,
        "playerLevel": 948,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 420,
        "ratingOffense": 345,
        "ratingTeamplay": 355,
        "score": 6,
        "skassists": 0,
        "skbs": 4,
        "skdeflections": 0,
        "skfol": 27,
        "skfopct": 71.88,
        "skfow": 9,
        "skgiveaways": 28,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 28,
        "skinterceptions": 26,
        "skpassattempts": 60,
        "skpasses": 38,
        "skpasspct": 319.59,
        "skpenaltiesdrawn": 4,
        "skpim": 33,
        "skpkclearzone": 2,
        "skplusmin": -13,
        "skpossession": 608,
        "skppg": 0,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 9,
        "skshotonnetpct": 216.67,
        "skshotpct": 50,
        "skshots": 6,
        "sktakeaways": 18,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 336,
        "toiseconds": 20195
      },
      "416581": {
        "class": 53,
        "glbrksavepct": 1,
        "glbrksaves": 1,
        "glbrkshots": 1,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 1,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.83,
        "glsaves": 5,
        "glshots": 6,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2495688,
        "opponentScore": 6,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1329,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 455,
        "ratingOffense": 515,
        "ratingTeamplay": 460,
        "score": 36,
        "skassists": 8,
        "skbs": 3,
        "skdeflections": 2,
        "skfol": 9,
        "skfopct": 75.68,
        "skfow": 28,
        "skgiveaways": 52,
        "skgoals": 6,
        "skgwg": 0,
        "skhits": 15,
        "skinterceptions": 23,
        "skpassattempts": 79,
        "skpasses": 58,
        "skpasspct": 346.03,
        "skpenaltiesdrawn": 8,
        "skpim": 24,
        "skpkclearzone": 2,
        "skplusmin": 15,
        "skpossession": 1292,
        "skppg": 2,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 35,
        "skshotonnetpct": 378.1,
        "skshotpct": 81.9,
        "skshots": 27,
        "sktakeaways": 5,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 345,
        "toiseconds": 20711
      }
    }
  },
  {
    "matchId": "6680107330049",
    "timestamp": 1663204215,
    "timeAgo": {
      "number": 22,
      "unit": "hours"
    },
    "clubs": {
      "415948": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "4",
        "gfraw": "1",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "416581",
        "opponentScore": "4",
        "opponentTeamArtAbbr": "DROP",
        "passa": "50",
        "passc": "28",
        "ppg": "0",
        "ppo": "0",
        "result": "2",
        "score": "1",
        "scoreString": "1 - 4",
        "shots": "7",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "100",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Ontario Wolvess",
          "clubId": 415948,
          "regionId": 5,
          "teamId": 5158,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "91",
            "useBaseAsset": "1"
          }
        },
        "goals": "1",
        "goalsAgainst": "4"
      },
      "416581": {
        "clubDivision": "5",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "4",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "415948",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "112",
        "passc": "76",
        "ppg": "0",
        "ppo": "2",
        "result": "1",
        "score": "4",
        "scoreString": "4 - 1",
        "shots": "25",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "747",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Screaming Eagles",
          "clubId": 416581,
          "regionId": 5,
          "teamId": 5171,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "233",
            "useBaseAsset": "1"
          }
        },
        "goals": "4",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "415948": {
        "268730339": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "4",
          "glgaa": "4.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.84",
          "glsaves": "21",
          "glshots": "25",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "75.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "35.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Rangerfan142"
        },
        "885391995": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "111",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "80.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "80.00",
          "score": "1",
          "skassists": "0",
          "skbs": "3",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "10",
          "skinterceptions": "5",
          "skpassattempts": "14",
          "skpasses": "8",
          "skpasspct": "57.14",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-3",
          "skpossession": "99",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "JoeK0325"
        },
        "1240123792": {
          "class": "5",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "76",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "90.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "9",
          "skinterceptions": "5",
          "skpassattempts": "9",
          "skpasses": "7",
          "skpasspct": "77.78",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-3",
          "skpossession": "76",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BlazedNBroke420"
        },
        "1855917359": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "175",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "90.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "16",
          "skfopct": "23.81",
          "skfow": "5",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "4",
          "skpassattempts": "9",
          "skpasses": "5",
          "skpasspct": "55.56",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-3",
          "skpossession": "97",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "ThatGuyCleary56"
        },
        "1985034943": {
          "class": "3",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "263",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "60.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "30.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "5",
          "skpassattempts": "10",
          "skpasses": "3",
          "skpasspct": "30.00",
          "skpenaltiesdrawn": "0",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "136",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "56",
          "toiseconds": "3362",
          "playername": "silky_johnsson22"
        },
        "1005164253946": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "4",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "24",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "70.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "55.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "3",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "9",
          "skpassattempts": "7",
          "skpasses": "5",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-3",
          "skpossession": "137",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "5",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Ranchero215"
        }
      },
      "416581": {
        "182377262": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "288",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "75.00",
          "score": "4",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "7",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "10",
          "skpassattempts": "21",
          "skpasses": "16",
          "skpasspct": "76.19",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "237",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BigPapaPumpkin"
        },
        "384513004": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "232",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "65.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "50.00",
          "score": "4",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "6",
          "skpassattempts": "18",
          "skpasses": "11",
          "skpasspct": "61.11",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "204",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "62.50",
          "skshotpct": "20.00",
          "skshots": "5",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Sweht_-"
        },
        "665487349": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "1.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.86",
          "glsaves": "6",
          "glshots": "7",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "142",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "60.00",
          "score": "4",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "MrBlondeMedia"
        },
        "1482142235": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "209",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "65.00",
          "score": "4",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "17",
          "skpasses": "10",
          "skpasspct": "58.82",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "216",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "14",
          "skshotonnetpct": "85.71",
          "skshotpct": "16.67",
          "skshots": "12",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Complexitii-"
        },
        "1780423381": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "60.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "90.00",
          "score": "4",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "1",
          "skfol": "5",
          "skfopct": "76.19",
          "skfow": "16",
          "skgiveaways": "10",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "0",
          "skpassattempts": "27",
          "skpasses": "17",
          "skpasspct": "62.96",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "350",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "50.00",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "xDynamicEntryx"
        },
        "1927694899": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "415948",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "191",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "65.00",
          "score": "4",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "4",
          "skpassattempts": "29",
          "skpasses": "22",
          "skpasspct": "75.86",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "338",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "EvanK50"
        }
      }
    },
    "aggregate": {
      "415948": {
        "class": 48,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 4,
        "glgaa": 4,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.84,
        "glsaves": 21,
        "glshots": 25,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2499486,
        "opponentScore": 24,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 948,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 450,
        "ratingOffense": 360,
        "ratingTeamplay": 355,
        "score": 6,
        "skassists": 0,
        "skbs": 5,
        "skdeflections": 0,
        "skfol": 16,
        "skfopct": 23.81,
        "skfow": 5,
        "skgiveaways": 29,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 25,
        "skinterceptions": 28,
        "skpassattempts": 49,
        "skpasses": 28,
        "skpasspct": 291.91,
        "skpenaltiesdrawn": 0,
        "skpim": 4,
        "skpkclearzone": 4,
        "skplusmin": -15,
        "skpossession": 545,
        "skppg": 0,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 8,
        "skshotonnetpct": 225,
        "skshotpct": 100,
        "skshots": 6,
        "sktakeaways": 19,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 356,
        "toiseconds": 21362
      },
      "416581": {
        "class": 52,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 1,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.86,
        "glsaves": 6,
        "glshots": 7,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2495688,
        "opponentScore": 6,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1361,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 395,
        "ratingOffense": 500,
        "ratingTeamplay": 405,
        "score": 24,
        "skassists": 8,
        "skbs": 0,
        "skdeflections": 1,
        "skfol": 5,
        "skfopct": 76.19,
        "skfow": 16,
        "skgiveaways": 47,
        "skgoals": 4,
        "skgwg": 0,
        "skhits": 11,
        "skinterceptions": 23,
        "skpassattempts": 112,
        "skpasses": 76,
        "skpasspct": 334.94,
        "skpenaltiesdrawn": 2,
        "skpim": 0,
        "skpkclearzone": 0,
        "skplusmin": 15,
        "skpossession": 1345,
        "skppg": 0,
        "sksaucerpasses": 2,
        "skshg": 0,
        "skshotattempts": 37,
        "skshotonnetpct": 314.88,
        "skshotpct": 61.67,
        "skshots": 25,
        "sktakeaways": 8,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 360,
        "toiseconds": 21600
      }
    }
  },
  {
    "matchId": "6724979230090",
    "timestamp": 1663725682,
    "timeAgo": {
      "number": 2,
      "unit": "days"
    },
    "clubs": {
      "120212": {
        "clubDivision": "10",
        "cNhlOnlineGameType": "5",
        "garaw": "5",
        "gfraw": "6",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "5",
        "opponentTeamArtAbbr": "DROP",
        "passa": "114",
        "passc": "85",
        "ppg": "0",
        "ppo": "1",
        "result": "1",
        "score": "6",
        "scoreString": "6 - 5",
        "shots": "16",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "559",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Catalyst",
          "clubId": 120212,
          "regionId": 5,
          "teamId": 5179,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "239",
            "useBaseAsset": "1"
          }
        },
        "goals": "6",
        "goalsAgainst": "5"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "6",
        "gfraw": "5",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "120212",
        "opponentScore": "6",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "92",
        "passc": "75",
        "ppg": "0",
        "ppo": "2",
        "result": "2",
        "score": "5",
        "scoreString": "5 - 6",
        "shots": "14",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "375",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "5",
        "goalsAgainst": "6"
      }
    },
    "players": {
      "120212": {
        "225613703": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "5",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "204",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "50.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "40.00",
          "score": "6",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "3",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "23",
          "skpasses": "17",
          "skpasspct": "73.91",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "206",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "60.00",
          "skshotpct": "100.00",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3480",
          "playername": "TheMarker_1"
        },
        "791376749": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "5",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "207",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "65.00",
          "score": "6",
          "skassists": "2",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "8",
          "skgoals": "1",
          "skgwg": "1",
          "skhits": "1",
          "skinterceptions": "7",
          "skpassattempts": "21",
          "skpasses": "17",
          "skpasspct": "80.95",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "2",
          "skplusmin": "1",
          "skpossession": "152",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "50.00",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "7",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Itz_Nifty_"
        },
        "1128072336": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "5",
          "glgaa": "5.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "1",
          "glsavepct": "0.64",
          "glsaves": "9",
          "glshots": "14",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "5",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "171",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "35.00",
          "ratingTeamplay": "65.00",
          "score": "6",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "MirrorParty"
        },
        "1903088278": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "5",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "60.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "60.00",
          "score": "6",
          "skassists": "3",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "28",
          "skpasses": "17",
          "skpasspct": "60.71",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "314",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "44",
          "toiseconds": "2626",
          "playername": "xHyper-27"
        },
        "1979919589": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "5",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "228",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "50.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "50.00",
          "score": "6",
          "skassists": "2",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "100.00",
          "skfow": "1",
          "skgiveaways": "3",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "4",
          "skpassattempts": "17",
          "skpasses": "13",
          "skpasspct": "76.47",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "150",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "10",
          "skshotonnetpct": "70.00",
          "skshotpct": "0.00",
          "skshots": "7",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "TheBossNYC"
        },
        "1998787278": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "5",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "172",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "65.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "70.00",
          "score": "6",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "19",
          "skfopct": "40.62",
          "skfow": "13",
          "skgiveaways": "8",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "5",
          "skpassattempts": "19",
          "skpasses": "15",
          "skpasspct": "78.95",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "299",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "33.33",
          "skshotpct": "200.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Enzoren-"
        }
      },
      "440740": {
        "261807399": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "6",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "195",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "60.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "95.00",
          "score": "5",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "14",
          "skfopct": "57.58",
          "skfow": "19",
          "skgiveaways": "3",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "1",
          "skpassattempts": "8",
          "skpasses": "6",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "59",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BingBongByron69"
        },
        "655079392": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "1",
          "glga": "6",
          "glgaa": "6.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.62",
          "glsaves": "10",
          "glshots": "16",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "6",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "30",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "80.00",
          "ratingOffense": "45.00",
          "ratingTeamplay": "40.00",
          "score": "5",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "SVNickNitro"
        },
        "1135212727": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "6",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "162",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "70.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "55.00",
          "score": "5",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "2",
          "skpassattempts": "24",
          "skpasses": "21",
          "skpasspct": "87.50",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "210",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3480",
          "playername": "Mr_Janack12"
        },
        "1867569276": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "6",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "207",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "60.00",
          "score": "5",
          "skassists": "3",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "13",
          "skpassattempts": "21",
          "skpasses": "18",
          "skpasspct": "85.71",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "146",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "9",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "xVerci-"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "6",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "60.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "70.00",
          "score": "5",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "16",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "7",
          "skpassattempts": "23",
          "skpasses": "19",
          "skpasspct": "82.61",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "265",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "66.67",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "4",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "A-H-O_20-"
        },
        "1004704581653": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "6",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "256",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "85.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "65.00",
          "score": "5",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "14",
          "skpasses": "10",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "192",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "80.00",
          "skshotpct": "50.00",
          "skshots": "4",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "xBxllxgxrde-"
        }
      }
    },
    "aggregate": {
      "120212": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 5,
        "glgaa": 5,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 1,
        "glsavepct": 0.64,
        "glsaves": 9,
        "glshots": 14,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2644440,
        "opponentScore": 30,
        "opponentTeamId": 3006,
        "player_dnf": 1,
        "playerLevel": 1184,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 370,
        "ratingOffense": 510,
        "ratingTeamplay": 350,
        "score": 36,
        "skassists": 8,
        "skbs": 5,
        "skdeflections": 0,
        "skfol": 19,
        "skfopct": 140.62,
        "skfow": 14,
        "skgiveaways": 43,
        "skgoals": 6,
        "skgwg": 1,
        "skhits": 7,
        "skinterceptions": 20,
        "skpassattempts": 108,
        "skpasses": 79,
        "skpasspct": 370.99,
        "skpenaltiesdrawn": 1,
        "skpim": 4,
        "skpkclearzone": 2,
        "skplusmin": 6,
        "skpossession": 1121,
        "skppg": 0,
        "sksaucerpasses": 4,
        "skshg": 0,
        "skshotattempts": 22,
        "skshotonnetpct": 213.33,
        "skshotpct": 350,
        "skshots": 13,
        "sktakeaways": 14,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 340,
        "toiseconds": 20387
      },
      "440740": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 1,
        "glga": 6,
        "glgaa": 6,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.62,
        "glsaves": 10,
        "glshots": 16,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 721272,
        "opponentScore": 36,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1149,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 425,
        "ratingOffense": 495,
        "ratingTeamplay": 385,
        "score": 30,
        "skassists": 8,
        "skbs": 3,
        "skdeflections": 0,
        "skfol": 14,
        "skfopct": 57.58,
        "skfow": 19,
        "skgiveaways": 35,
        "skgoals": 5,
        "skgwg": 0,
        "skhits": 7,
        "skinterceptions": 26,
        "skpassattempts": 90,
        "skpasses": 74,
        "skpasspct": 402.25,
        "skpenaltiesdrawn": 2,
        "skpim": 2,
        "skpkclearzone": 1,
        "skplusmin": -5,
        "skpossession": 872,
        "skppg": 0,
        "sksaucerpasses": 3,
        "skshg": 0,
        "skshotattempts": 18,
        "skshotonnetpct": 371.67,
        "skshotpct": 208.33,
        "skshots": 13,
        "sktakeaways": 19,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 358,
        "toiseconds": 21480
      }
    }
  },
  {
    "matchId": "6726716560166",
    "timestamp": 1663722565,
    "timeAgo": {
      "number": 2,
      "unit": "days"
    },
    "clubs": {
      "416581": {
        "clubDivision": "5",
        "cNhlOnlineGameType": "5",
        "garaw": "2",
        "gfraw": "1",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "2",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "104",
        "passc": "75",
        "ppg": "0",
        "ppo": "0",
        "result": "6",
        "score": "1",
        "scoreString": "1 - 2",
        "shots": "12",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "559",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": null,
        "goals": "1",
        "goalsAgainst": "2"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "2",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "416581",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "DROP",
        "passa": "102",
        "passc": "70",
        "ppg": "0",
        "ppo": "3",
        "result": "5",
        "score": "2",
        "scoreString": "2 - 1",
        "shots": "17",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "303",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "2",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "416581": {
        "187754842": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "133",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "90.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "7",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "5",
          "skpassattempts": "16",
          "skpasses": "11",
          "skpasspct": "68.75",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "107",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "D_is_key"
        },
        "665487349": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "1",
          "glga": "2",
          "glgaa": "1.52",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.88",
          "glsaves": "15",
          "glshots": "17",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "142",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "75.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "50.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "MrBlondeMedia"
        },
        "1021379901": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "194",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "95.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "13",
          "skfopct": "45.83",
          "skfow": "11",
          "skgiveaways": "8",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "13",
          "skpassattempts": "20",
          "skpasses": "18",
          "skpasspct": "90.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "323",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "1",
          "skshotattempts": "11",
          "skshotonnetpct": "54.55",
          "skshotpct": "16.67",
          "skshots": "6",
          "sktakeaways": "6",
          "teamId": "501",
          "teamSide": "1",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "Ryy2Flyy-"
        },
        "1705605309": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "190",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "70.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "55.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "21",
          "skpasses": "14",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "216",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "77",
          "toiseconds": "4614",
          "playername": "Bxrtzyy-_-"
        },
        "1752079379": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "91",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "95.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "12",
          "skpassattempts": "24",
          "skpasses": "15",
          "skpasspct": "62.50",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "3",
          "skplusmin": "-1",
          "skpossession": "497",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "33.33",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "6",
          "teamId": "501",
          "teamSide": "1",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "iamsmokxy-_-"
        },
        "1780423381": {
          "class": "6",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "30.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "19",
          "skpasses": "14",
          "skpasspct": "73.68",
          "skpenaltiesdrawn": "0",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "266",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "20.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "75",
          "toiseconds": "4494",
          "playername": "xDynamicEntryx"
        }
      },
      "450721": {
        "244124053": {
          "class": "4",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "85.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "80.00",
          "score": "2",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "11",
          "skfopct": "54.17",
          "skfow": "13",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "8",
          "skinterceptions": "5",
          "skpassattempts": "18",
          "skpasses": "13",
          "skpasspct": "72.22",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "214",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "s-zeze-"
        },
        "386774169": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "226",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "95.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "50.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "2",
          "skgwg": "1",
          "skhits": "3",
          "skinterceptions": "12",
          "skpassattempts": "19",
          "skpasses": "14",
          "skpasspct": "73.68",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "278",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "83.33",
          "skshotpct": "40.00",
          "skshots": "5",
          "sktakeaways": "8",
          "teamId": "500",
          "teamSide": "0",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "HogIandov"
        },
        "1019650373": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "102",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "100.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "65.00",
          "score": "2",
          "skassists": "1",
          "skbs": "4",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "5",
          "skpassattempts": "17",
          "skpasses": "14",
          "skpasspct": "82.35",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "208",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "NYfrijoles"
        },
        "1133854144": {
          "class": "6",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "237",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "80.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "40.00",
          "score": "2",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "16",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "20",
          "skpasses": "9",
          "skpasspct": "45.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "249",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "80.00",
          "skshotpct": "0.00",
          "skshots": "4",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "Corbechkin"
        },
        "1915869717": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "168",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "100.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "50.00",
          "score": "2",
          "skassists": "1",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "11",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "25",
          "skpasses": "17",
          "skpasspct": "68.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "226",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "5",
          "teamId": "500",
          "teamSide": "0",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "Tchuky-7-"
        },
        "1926645693": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "0.76",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.92",
          "glsaves": "11",
          "glshots": "12",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "139",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "85.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "65.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "79",
          "toiseconds": "4733",
          "playername": "Rabb1tx14"
        }
      }
    },
    "aggregate": {
      "416581": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 1,
        "glga": 2,
        "glgaa": 1.52,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.88,
        "glsaves": 15,
        "glshots": 17,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2704326,
        "opponentScore": 12,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1049,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 490,
        "ratingOffense": 405,
        "ratingTeamplay": 330,
        "score": 6,
        "skassists": 1,
        "skbs": 3,
        "skdeflections": 0,
        "skfol": 13,
        "skfopct": 45.83,
        "skfow": 11,
        "skgiveaways": 49,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 8,
        "skinterceptions": 34,
        "skpassattempts": 100,
        "skpasses": 72,
        "skpasspct": 361.6,
        "skpenaltiesdrawn": 0,
        "skpim": 6,
        "skpkclearzone": 5,
        "skplusmin": -7,
        "skpossession": 1409,
        "skppg": 0,
        "sksaucerpasses": 6,
        "skshg": 1,
        "skshotattempts": 26,
        "skshotonnetpct": 182.88,
        "skshotpct": 16.67,
        "skshots": 12,
        "sktakeaways": 17,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 468,
        "toiseconds": 28040
      },
      "450721": {
        "class": 63,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 0.76,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.92,
        "glsaves": 11,
        "glshots": 12,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2499486,
        "opponentScore": 6,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1027,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 545,
        "ratingOffense": 435,
        "ratingTeamplay": 350,
        "score": 12,
        "skassists": 3,
        "skbs": 7,
        "skdeflections": 0,
        "skfol": 11,
        "skfopct": 54.17,
        "skfow": 13,
        "skgiveaways": 51,
        "skgoals": 2,
        "skgwg": 1,
        "skhits": 13,
        "skinterceptions": 29,
        "skpassattempts": 99,
        "skpasses": 67,
        "skpasspct": 341.25,
        "skpenaltiesdrawn": 1,
        "skpim": 0,
        "skpkclearzone": 0,
        "skplusmin": 5,
        "skpossession": 1175,
        "skppg": 0,
        "sksaucerpasses": 2,
        "skshg": 0,
        "skshotattempts": 20,
        "skshotonnetpct": 413.33,
        "skshotpct": 40,
        "skshots": 16,
        "sktakeaways": 20,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 474,
        "toiseconds": 28398
      }
    }
  },
  {
    "matchId": "6730037560258",
    "timestamp": 1663725147,
    "timeAgo": {
      "number": 2,
      "unit": "days"
    },
    "clubs": {
      "416581": {
        "clubDivision": "5",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "1",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "69",
        "passc": "46",
        "ppg": "0",
        "ppo": "2",
        "result": "2",
        "score": "1",
        "scoreString": "1 - 3",
        "shots": "9",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "386",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": null,
        "goals": "1",
        "goalsAgainst": "3"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "3",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "416581",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "DROP",
        "passa": "106",
        "passc": "77",
        "ppg": "1",
        "ppo": "5",
        "result": "1",
        "score": "3",
        "scoreString": "3 - 1",
        "shots": "13",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "258",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "416581": {
        "182377262": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "290",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "65.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "80.00",
          "score": "1",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "6",
          "skinterceptions": "8",
          "skpassattempts": "16",
          "skpasses": "13",
          "skpasspct": "81.25",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "2",
          "skplusmin": "-1",
          "skpossession": "209",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BigPapaPumpkin"
        },
        "665487349": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "3.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.77",
          "glsaves": "10",
          "glshots": "13",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "142",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "MrBlondeMedia"
        },
        "1482142235": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "209",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "65.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "60.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "16",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "8",
          "skpasses": "4",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "182",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "57.14",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Complexitii-"
        },
        "1705605309": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "190",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "100.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "60.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "17",
          "skfopct": "45.16",
          "skfow": "14",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "7",
          "skpassattempts": "14",
          "skpasses": "8",
          "skpasspct": "57.14",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "2",
          "skplusmin": "-1",
          "skpossession": "143",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "Bxrtzyy-_-"
        },
        "1752079379": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "91",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "40.00",
          "score": "1",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "10",
          "skpassattempts": "14",
          "skpasses": "8",
          "skpasspct": "57.14",
          "skpenaltiesdrawn": "0",
          "skpim": "6",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "254",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "5",
          "teamId": "501",
          "teamSide": "1",
          "toi": "54",
          "toiseconds": "3243",
          "playername": "iamsmokxy-_-"
        },
        "1780423381": {
          "class": "12",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "70.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "35.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "1",
          "skfopct": "50.00",
          "skfow": "1",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "6",
          "skpassattempts": "16",
          "skpasses": "12",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "258",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3595",
          "playername": "xDynamicEntryx"
        }
      },
      "450721": {
        "178617183": {
          "class": "21",
          "glbrksavepct": "1.00",
          "glbrksaves": "1",
          "glbrkshots": "1",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "1.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.89",
          "glsaves": "8",
          "glshots": "9",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "131",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "85.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3598",
          "playername": "falcolover"
        },
        "244124053": {
          "class": "4",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "85.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "90.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "15",
          "skfopct": "54.55",
          "skfow": "18",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "7",
          "skpassattempts": "24",
          "skpasses": "18",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "196",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "7",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "s-zeze-"
        },
        "386774169": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "226",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "80.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "14",
          "skpasses": "9",
          "skpasspct": "64.29",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "188",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "75.00",
          "skshotpct": "33.33",
          "skshots": "6",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "HogIandov"
        },
        "792179809": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "85.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "70.00",
          "score": "3",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "7",
          "skinterceptions": "6",
          "skpassattempts": "24",
          "skpasses": "18",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "155",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "6",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "xSandin38"
        },
        "1133854144": {
          "class": "6",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "237",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "80.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "70.00",
          "score": "3",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "1",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "1",
          "skgwg": "1",
          "skhits": "3",
          "skinterceptions": "4",
          "skpassattempts": "19",
          "skpasses": "14",
          "skpasspct": "73.68",
          "skpenaltiesdrawn": "3",
          "skpim": "2",
          "skpkclearzone": "2",
          "skplusmin": "1",
          "skpossession": "201",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "Corbechkin"
        },
        "1004931773441": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "416581",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "240",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "85.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "9",
          "skpassattempts": "24",
          "skpasses": "17",
          "skpasspct": "70.83",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "245",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "6",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Silverstxin"
        }
      }
    },
    "aggregate": {
      "416581": {
        "class": 62,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 3,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.77,
        "glsaves": 10,
        "glshots": 13,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2704326,
        "opponentScore": 18,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1221,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 435,
        "ratingOffense": 365,
        "ratingTeamplay": 320,
        "score": 6,
        "skassists": 2,
        "skbs": 2,
        "skdeflections": 0,
        "skfol": 18,
        "skfopct": 95.16,
        "skfow": 15,
        "skgiveaways": 54,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 16,
        "skinterceptions": 34,
        "skpassattempts": 68,
        "skpasses": 45,
        "skpasspct": 320.53,
        "skpenaltiesdrawn": 2,
        "skpim": 10,
        "skpkclearzone": 5,
        "skplusmin": -5,
        "skpossession": 1046,
        "skppg": 0,
        "sksaucerpasses": 2,
        "skshg": 0,
        "skshotattempts": 13,
        "skshotonnetpct": 223.81,
        "skshotpct": 25,
        "skshots": 7,
        "sktakeaways": 14,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 352,
        "toiseconds": 21119
      },
      "450721": {
        "class": 63,
        "glbrksavepct": 1,
        "glbrksaves": 1,
        "glbrkshots": 1,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 1,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.89,
        "glsaves": 8,
        "glshots": 9,
        "glsoperiods": 0,
        "isGuest": 5,
        "opponentClubId": 2499486,
        "opponentScore": 6,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1191,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 500,
        "ratingOffense": 450,
        "ratingTeamplay": 420,
        "score": 18,
        "skassists": 4,
        "skbs": 6,
        "skdeflections": 1,
        "skfol": 15,
        "skfopct": 54.55,
        "skfow": 18,
        "skgiveaways": 48,
        "skgoals": 3,
        "skgwg": 1,
        "skhits": 14,
        "skinterceptions": 29,
        "skpassattempts": 105,
        "skpasses": 76,
        "skpasspct": 358.8,
        "skpenaltiesdrawn": 4,
        "skpim": 4,
        "skpkclearzone": 3,
        "skplusmin": 5,
        "skpossession": 985,
        "skppg": 1,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 17,
        "skshotonnetpct": 350,
        "skshotpct": 66.66,
        "skshots": 12,
        "sktakeaways": 25,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 356,
        "toiseconds": 21360
      }
    }
  },
  {
    "matchId": "6732794670254",
    "timestamp": 1663808502,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "120212": {
        "clubDivision": "10",
        "cNhlOnlineGameType": "5",
        "garaw": "4",
        "gfraw": "3",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "4",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "124",
        "passc": "77",
        "ppg": "0",
        "ppo": "3",
        "result": "2",
        "score": "3",
        "scoreString": "3 - 4",
        "shots": "12",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "424",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Catalyst",
          "clubId": 120212,
          "regionId": 5,
          "teamId": 5179,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "239",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "4"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "4",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "120212",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "DROP",
        "passa": "74",
        "passc": "54",
        "ppg": "2",
        "ppo": "2",
        "result": "1",
        "score": "4",
        "scoreString": "4 - 3",
        "shots": "16",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "221",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "4",
        "goalsAgainst": "3"
      }
    },
    "players": {
      "120212": {
        "225613703": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "204",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "75.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "25",
          "skpasses": "17",
          "skpasspct": "68.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "203",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "66.67",
          "skshots": "3",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "TheMarker_1"
        },
        "1128072336": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "4",
          "glgaa": "4.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.75",
          "glsaves": "12",
          "glshots": "16",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "171",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "45.00",
          "ratingTeamplay": "45.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "MirrorParty"
        },
        "1613568747": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "224",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "1",
          "skpassattempts": "30",
          "skpasses": "18",
          "skpasspct": "60.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "231",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "59",
          "toiseconds": "3560",
          "playername": "nLands"
        },
        "1903088278": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "65.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "8",
          "skpassattempts": "32",
          "skpasses": "16",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "427",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3599",
          "playername": "xHyper-27"
        },
        "1979919589": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "228",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "60.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "45.00",
          "score": "3",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "1",
          "skpassattempts": "14",
          "skpasses": "7",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "115",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "62.50",
          "skshotpct": "20.00",
          "skshots": "5",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "59",
          "toiseconds": "3531",
          "playername": "TheBossNYC"
        },
        "1004161695637": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "4",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "151",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "85.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "22",
          "skfopct": "31.25",
          "skfow": "10",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "6",
          "skpassattempts": "23",
          "skpasses": "19",
          "skpasspct": "82.61",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "124",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "FxtDino-"
        }
      },
      "440740": {
        "207185895": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "161",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "100.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "50.00",
          "score": "4",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "15",
          "skpasses": "11",
          "skpasspct": "73.33",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "118",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "IVIelo"
        },
        "261807399": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "195",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "55.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "65.00",
          "score": "4",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "7",
          "skinterceptions": "1",
          "skpassattempts": "5",
          "skpasses": "4",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "86",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "133.33",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BingBongByron69"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "3.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.75",
          "glsaves": "9",
          "glshots": "12",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "45.00",
          "ratingTeamplay": "60.00",
          "score": "4",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Seagull_3134"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "203",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "95.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "75.00",
          "score": "4",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "6",
          "skinterceptions": "9",
          "skpassattempts": "21",
          "skpasses": "14",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "268",
          "skppg": "0",
          "sksaucerpasses": "6",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "33.33",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3599",
          "playername": "Bilal-I96I"
        },
        "1867569276": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "207",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "80.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "45.00",
          "score": "4",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "100.00",
          "skfow": "1",
          "skgiveaways": "3",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "6",
          "skpassattempts": "20",
          "skpasses": "17",
          "skpasspct": "85.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "159",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "xVerci-"
        },
        "1003797820592": {
          "class": "4",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "75.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "90.00",
          "score": "4",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "10",
          "skfopct": "67.74",
          "skfow": "21",
          "skgiveaways": "9",
          "skgoals": "3",
          "skgwg": "1",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "13",
          "skpasses": "8",
          "skpasspct": "61.54",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "204",
          "skppg": "2",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "100.00",
          "skshotpct": "50.00",
          "skshots": "6",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "A-H-O_20-"
        }
      }
    },
    "aggregate": {
      "120212": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 4,
        "glgaa": 4,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.75,
        "glsaves": 12,
        "glshots": 16,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 24,
        "opponentTeamId": 3000,
        "player_dnf": 1,
        "playerLevel": 1180,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 425,
        "ratingOffense": 445,
        "ratingTeamplay": 310,
        "score": 18,
        "skassists": 6,
        "skbs": 2,
        "skdeflections": 0,
        "skfol": 22,
        "skfopct": 31.25,
        "skfow": 10,
        "skgiveaways": 31,
        "skgoals": 3,
        "skgwg": 0,
        "skhits": 10,
        "skinterceptions": 19,
        "skpassattempts": 124,
        "skpasses": 77,
        "skpasspct": 310.61,
        "skpenaltiesdrawn": 3,
        "skpim": 4,
        "skpkclearzone": 0,
        "skplusmin": 5,
        "skpossession": 1100,
        "skppg": 0,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 18,
        "skshotonnetpct": 329.17,
        "skshotpct": 86.67,
        "skshots": 11,
        "sktakeaways": 10,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 358,
        "toiseconds": 21490
      },
      "440740": {
        "class": 72,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 3,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.75,
        "glsaves": 9,
        "glshots": 12,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 721272,
        "opponentScore": 18,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1253,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 470,
        "ratingOffense": 440,
        "ratingTeamplay": 385,
        "score": 24,
        "skassists": 5,
        "skbs": 2,
        "skdeflections": 0,
        "skfol": 10,
        "skfopct": 167.74,
        "skfow": 22,
        "skgiveaways": 29,
        "skgoals": 4,
        "skgwg": 1,
        "skhits": 15,
        "skinterceptions": 20,
        "skpassattempts": 74,
        "skpasses": 54,
        "skpasspct": 366.54,
        "skpenaltiesdrawn": 1,
        "skpim": 6,
        "skpkclearzone": 4,
        "skplusmin": -5,
        "skpossession": 835,
        "skppg": 2,
        "sksaucerpasses": 6,
        "skshg": 0,
        "skshotattempts": 18,
        "skshotonnetpct": 366.66,
        "skshotpct": 75,
        "skshots": 14,
        "sktakeaways": 11,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 356,
        "toiseconds": 21361
      }
    }
  },
  {
    "matchId": "6736550890206",
    "timestamp": 1663811823,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "120212": {
        "clubDivision": "10",
        "cNhlOnlineGameType": "5",
        "garaw": "2",
        "gfraw": "1",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "2",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "134",
        "passc": "92",
        "ppg": "1",
        "ppo": "2",
        "result": "6",
        "score": "1",
        "scoreString": "1 - 2",
        "shots": "6",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "354",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Catalyst",
          "clubId": 120212,
          "regionId": 5,
          "teamId": 5179,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "239",
            "useBaseAsset": "1"
          }
        },
        "goals": "1",
        "goalsAgainst": "2"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "2",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "120212",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "DROP",
        "passa": "128",
        "passc": "92",
        "ppg": "1",
        "ppo": "3",
        "result": "5",
        "score": "2",
        "scoreString": "2 - 1",
        "shots": "19",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "433",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "2",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "120212": {
        "362296239": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "206",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "100.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "60.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "1",
          "skfopct": "50.00",
          "skfow": "1",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "4",
          "skpassattempts": "26",
          "skpasses": "19",
          "skpasspct": "73.08",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "315",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "80",
          "toiseconds": "4786",
          "playername": "KuttnKornerz"
        },
        "941432623": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "195",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "100.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "25.00",
          "score": "1",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "9",
          "skpassattempts": "27",
          "skpasses": "21",
          "skpasspct": "77.78",
          "skpenaltiesdrawn": "0",
          "skpim": "4",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "190",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "33.33",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "6",
          "teamId": "501",
          "teamSide": "1",
          "toi": "77",
          "toiseconds": "4606",
          "playername": "dudethisishard"
        },
        "1128072336": {
          "class": "21",
          "glbrksavepct": "1.00",
          "glbrksaves": "1",
          "glbrkshots": "1",
          "gldsaves": "0",
          "glga": "2",
          "glgaa": "1.50",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.89",
          "glsaves": "17",
          "glshots": "19",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "171",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "75.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "80",
          "toiseconds": "4786",
          "playername": "MirrorParty"
        },
        "1303636499": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "213",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "100.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "80.00",
          "score": "1",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "1",
          "skfol": "12",
          "skfopct": "50.00",
          "skfow": "12",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "8",
          "skpassattempts": "28",
          "skpasses": "19",
          "skpasspct": "67.86",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "257",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "6",
          "teamId": "501",
          "teamSide": "1",
          "toi": "78",
          "toiseconds": "4707",
          "playername": "Sakxc-"
        },
        "1613568747": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "224",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "100.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "90.00",
          "score": "1",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "22",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "10",
          "skinterceptions": "7",
          "skpassattempts": "33",
          "skpasses": "22",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "2",
          "skplusmin": "-1",
          "skpossession": "465",
          "skppg": "1",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "80",
          "toiseconds": "4786",
          "playername": "nLands"
        },
        "1004161695637": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "151",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "85.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "50.00",
          "score": "1",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "1",
          "skfopct": "50.00",
          "skfow": "1",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "5",
          "skpassattempts": "18",
          "skpasses": "10",
          "skpasspct": "55.56",
          "skpenaltiesdrawn": "0",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "118",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "77",
          "toiseconds": "4638",
          "playername": "FxtDino-"
        }
      },
      "440740": {
        "207185895": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "161",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "100.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "85.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "9",
          "skinterceptions": "13",
          "skpassattempts": "24",
          "skpasses": "20",
          "skpasspct": "83.33",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "279",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "100.00",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "7",
          "teamId": "500",
          "teamSide": "0",
          "toi": "78",
          "toiseconds": "4666",
          "playername": "IVIelo"
        },
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "95.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "60.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "4",
          "skpassattempts": "23",
          "skpasses": "20",
          "skpasspct": "86.96",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "218",
          "skppg": "0",
          "sksaucerpasses": "4",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "33.33",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "80",
          "toiseconds": "4786",
          "playername": "Sauc---y"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "100.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "85.00",
          "score": "2",
          "skassists": "1",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "14",
          "skfopct": "51.72",
          "skfow": "15",
          "skgiveaways": "19",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "9",
          "skpassattempts": "29",
          "skpasses": "19",
          "skpasspct": "65.52",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "268",
          "skppg": "0",
          "sksaucerpasses": "5",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "80",
          "toiseconds": "4786",
          "playername": "BarryWoodsDong"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "0.75",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.83",
          "glsaves": "5",
          "glshots": "6",
          "glsoperiods": "2",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "75.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "60.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "80",
          "toiseconds": "4786",
          "playername": "Seagull_3134"
        },
        "1975762195": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "85.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "35.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "1",
          "skgwg": "1",
          "skhits": "3",
          "skinterceptions": "5",
          "skpassattempts": "21",
          "skpasses": "15",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "372",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "10",
          "skshotonnetpct": "80.00",
          "skshotpct": "12.50",
          "skshots": "8",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "79",
          "toiseconds": "4767",
          "playername": "Vaniskov"
        },
        "1999624193": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "90.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "60.00",
          "score": "2",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "16",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "4",
          "skpassattempts": "31",
          "skpasses": "18",
          "skpasspct": "58.06",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "277",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "10",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "5",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "80",
          "toiseconds": "4786",
          "playername": "FlowSoClean"
        }
      }
    },
    "aggregate": {
      "120212": {
        "class": 69,
        "glbrksavepct": 1,
        "glbrksaves": 1,
        "glbrkshots": 1,
        "gldsaves": 0,
        "glga": 2,
        "glgaa": 1.5,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.89,
        "glsaves": 17,
        "glshots": 19,
        "glsoperiods": 0,
        "isGuest": 3,
        "opponentClubId": 2644440,
        "opponentScore": 12,
        "opponentTeamId": 3000,
        "player_dnf": 1,
        "playerLevel": 1160,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 560,
        "ratingOffense": 430,
        "ratingTeamplay": 350,
        "score": 6,
        "skassists": 2,
        "skbs": 5,
        "skdeflections": 1,
        "skfol": 14,
        "skfopct": 150,
        "skfow": 14,
        "skgiveaways": 54,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 19,
        "skinterceptions": 33,
        "skpassattempts": 132,
        "skpasses": 91,
        "skpasspct": 340.95,
        "skpenaltiesdrawn": 2,
        "skpim": 8,
        "skpkclearzone": 3,
        "skplusmin": -5,
        "skpossession": 1345,
        "skppg": 1,
        "sksaucerpasses": 2,
        "skshg": 0,
        "skshotattempts": 14,
        "skshotonnetpct": 200,
        "skshotpct": 33.33,
        "skshots": 6,
        "sktakeaways": 18,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 472,
        "toiseconds": 28309
      },
      "440740": {
        "class": 52,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 0.75,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.83,
        "glsaves": 5,
        "glshots": 6,
        "glsoperiods": 2,
        "isGuest": 3,
        "opponentClubId": 721272,
        "opponentScore": 6,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 895,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 545,
        "ratingOffense": 460,
        "ratingTeamplay": 385,
        "score": 12,
        "skassists": 2,
        "skbs": 2,
        "skdeflections": 0,
        "skfol": 14,
        "skfopct": 51.72,
        "skfow": 15,
        "skgiveaways": 54,
        "skgoals": 2,
        "skgwg": 1,
        "skhits": 18,
        "skinterceptions": 35,
        "skpassattempts": 128,
        "skpasses": 92,
        "skpasspct": 365.3,
        "skpenaltiesdrawn": 3,
        "skpim": 4,
        "skpkclearzone": 1,
        "skplusmin": 5,
        "skpossession": 1414,
        "skppg": 1,
        "sksaucerpasses": 10,
        "skshg": 0,
        "skshotattempts": 28,
        "skshotonnetpct": 330,
        "skshotpct": 62.5,
        "skshots": 18,
        "sktakeaways": 21,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 477,
        "toiseconds": 28577
      }
    }
  },
  {
    "matchId": "6738587570336",
    "timestamp": 1663895649,
    "timeAgo": {
      "number": 14,
      "unit": "hours"
    },
    "clubs": {
      "120212": {
        "clubDivision": "10",
        "cNhlOnlineGameType": "5",
        "garaw": "2",
        "gfraw": "1",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "2",
        "opponentTeamArtAbbr": "DROP",
        "passa": "82",
        "passc": "56",
        "ppg": "1",
        "ppo": "3",
        "result": "2",
        "score": "1",
        "scoreString": "1 - 2",
        "shots": "11",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "225",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "Catalyst",
          "clubId": 120212,
          "regionId": 5,
          "teamId": 5179,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "239",
            "useBaseAsset": "1"
          }
        },
        "goals": "1",
        "goalsAgainst": "2"
      },
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "2",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "120212",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "101",
        "passc": "79",
        "ppg": "1",
        "ppo": "2",
        "result": "1",
        "score": "2",
        "scoreString": "2 - 1",
        "shots": "12",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "374",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "2",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "120212": {
        "362296239": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "206",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "75.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "1",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "8",
          "skpassattempts": "16",
          "skpasses": "16",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "165",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "6",
          "teamId": "500",
          "teamSide": "0",
          "toi": "59",
          "toiseconds": "3538",
          "playername": "KuttnKornerz"
        },
        "941432623": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "195",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "95.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "70.00",
          "score": "1",
          "skassists": "0",
          "skbs": "3",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "5",
          "skpassattempts": "16",
          "skpasses": "12",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "100",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "dudethisishard"
        },
        "1128072336": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "2",
          "glgaa": "2.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.83",
          "glsaves": "10",
          "glshots": "12",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "172",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "MirrorParty"
        },
        "1303636499": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "213",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "75.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "15",
          "skfopct": "37.50",
          "skfow": "9",
          "skgiveaways": "7",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "2",
          "skpassattempts": "13",
          "skpasses": "7",
          "skpasspct": "53.85",
          "skpenaltiesdrawn": "2",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "172",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Sakxc-"
        },
        "1903088278": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "80.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "65.00",
          "score": "1",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "13",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "10",
          "skpassattempts": "27",
          "skpasses": "15",
          "skpasspct": "55.56",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "418",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "xHyper-27"
        },
        "1979919589": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "228",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "50.00",
          "score": "1",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "2",
          "skpassattempts": "9",
          "skpasses": "5",
          "skpasspct": "55.56",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "78",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "50.00",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "TheBossNYC"
        }
      },
      "440740": {
        "207185895": {
          "class": "11",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "161",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "80.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "70.00",
          "score": "2",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "7",
          "skpassattempts": "21",
          "skpasses": "17",
          "skpasspct": "80.95",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "170",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3480",
          "playername": "IVIelo"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "80.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "70.00",
          "score": "2",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "8",
          "skfopct": "61.90",
          "skfow": "13",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "17",
          "skpasses": "11",
          "skpasspct": "64.71",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "171",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3481",
          "playername": "BarryWoodsDong"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "1.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "1",
          "glsavepct": "0.91",
          "glsaves": "10",
          "glshots": "11",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "65.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Seagull_3134"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "203",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "100.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "65.00",
          "score": "2",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "25",
          "skpasses": "22",
          "skpasspct": "88.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "300",
          "skppg": "0",
          "sksaucerpasses": "5",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Bilal-I96I"
        },
        "1975762195": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "75.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "70.00",
          "score": "2",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "1",
          "skfopct": "66.67",
          "skfow": "2",
          "skgiveaways": "8",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "6",
          "skpassattempts": "17",
          "skpasses": "12",
          "skpasspct": "70.59",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "256",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "33.33",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Vaniskov"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "120212",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "75.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "30.00",
          "score": "2",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "4",
          "skpassattempts": "20",
          "skpasses": "16",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "285",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "57.14",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "59",
          "toiseconds": "3558",
          "playername": "A-H-O_20-"
        }
      }
    },
    "aggregate": {
      "120212": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 2,
        "glgaa": 2,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.83,
        "glsaves": 10,
        "glshots": 12,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 12,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1216,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 460,
        "ratingOffense": 390,
        "ratingTeamplay": 340,
        "score": 6,
        "skassists": 2,
        "skbs": 9,
        "skdeflections": 0,
        "skfol": 15,
        "skfopct": 37.5,
        "skfow": 9,
        "skgiveaways": 33,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 11,
        "skinterceptions": 27,
        "skpassattempts": 81,
        "skpasses": 55,
        "skpasspct": 339.97,
        "skpenaltiesdrawn": 3,
        "skpim": 4,
        "skpkclearzone": 1,
        "skplusmin": -5,
        "skpossession": 933,
        "skppg": 1,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 16,
        "skshotonnetpct": 166.67,
        "skshotpct": 25,
        "skshots": 8,
        "sktakeaways": 16,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 357,
        "toiseconds": 21419
      },
      "440740": {
        "class": 52,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 1,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 1,
        "glsavepct": 0.91,
        "glsaves": 10,
        "glshots": 11,
        "glsoperiods": 0,
        "isGuest": 3,
        "opponentClubId": 721272,
        "opponentScore": 6,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1016,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 470,
        "ratingOffense": 435,
        "ratingTeamplay": 370,
        "score": 12,
        "skassists": 4,
        "skbs": 3,
        "skdeflections": 0,
        "skfol": 9,
        "skfopct": 128.57,
        "skfow": 15,
        "skgiveaways": 43,
        "skgoals": 2,
        "skgwg": 0,
        "skhits": 9,
        "skinterceptions": 22,
        "skpassattempts": 100,
        "skpasses": 78,
        "skpasspct": 384.25,
        "skpenaltiesdrawn": 2,
        "skpim": 6,
        "skpkclearzone": 3,
        "skplusmin": 5,
        "skpossession": 1182,
        "skppg": 1,
        "sksaucerpasses": 6,
        "skshg": 0,
        "skshotattempts": 23,
        "skshotonnetpct": 240.47,
        "skshotpct": 75,
        "skshots": 12,
        "sktakeaways": 11,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 355,
        "toiseconds": 21319
      }
    }
  },
  {
    "matchId": "6778395440212",
    "timestamp": 1664327099,
    "timeAgo": {
      "number": 2,
      "unit": "days"
    },
    "clubs": {
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "2",
        "gfraw": "3",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "2",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "116",
        "passc": "84",
        "ppg": "1",
        "ppo": "2",
        "result": "5",
        "score": "3",
        "scoreString": "3 - 2",
        "shots": "11",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "333",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "2"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "2",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "DROP",
        "passa": "90",
        "passc": "69",
        "ppg": "1",
        "ppo": "3",
        "result": "6",
        "score": "2",
        "scoreString": "2 - 3",
        "shots": "11",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "199",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "2",
        "goalsAgainst": "3"
      }
    },
    "players": {
      "440740": {
        "655079392": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "1",
          "glga": "2",
          "glgaa": "1.94",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "1",
          "glsavepct": "0.82",
          "glsaves": "9",
          "glshots": "11",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "30",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "SVNickNitro"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "80.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "25.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "8",
          "skfopct": "55.56",
          "skfow": "10",
          "skgiveaways": "13",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "21",
          "skpasses": "15",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "0",
          "skpim": "6",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "221",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3483",
          "playername": "BarryWoodsDong"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "203",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "90.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "21",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "2",
          "skpassattempts": "32",
          "skpasses": "20",
          "skpasspct": "62.50",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "304",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "Bilal-I96I"
        },
        "1975762195": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "75.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "1",
          "skfopct": "75.00",
          "skfow": "3",
          "skgiveaways": "7",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "17",
          "skpasses": "14",
          "skpasspct": "82.35",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "218",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "83.33",
          "skshotpct": "20.00",
          "skshots": "5",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "Vaniskov"
        },
        "1999624193": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "95.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "2",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "9",
          "skpassattempts": "25",
          "skpasses": "20",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "2",
          "skplusmin": "1",
          "skpossession": "203",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "5",
          "teamId": "501",
          "teamSide": "1",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "FlowSoClean"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "80.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "45.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "2",
          "skgwg": "1",
          "skhits": "0",
          "skinterceptions": "10",
          "skpassattempts": "21",
          "skpasses": "15",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "194",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "57.14",
          "skshotpct": "50.00",
          "skshots": "4",
          "sktakeaways": "6",
          "teamId": "501",
          "teamSide": "1",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "A-H-O_20-"
        }
      },
      "450721": {
        "244124053": {
          "class": "4",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "156",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "80.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "60.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "13",
          "skfopct": "35.00",
          "skfow": "7",
          "skgiveaways": "8",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "6",
          "skinterceptions": "6",
          "skpassattempts": "21",
          "skpasses": "18",
          "skpasspct": "85.71",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "251",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "50.00",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "5",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3613",
          "playername": "s-zeze-"
        },
        "792179809": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "95.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "65.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "11",
          "skinterceptions": "8",
          "skpassattempts": "18",
          "skpasses": "15",
          "skpasspct": "83.33",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "99",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "57.14",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "61",
          "toiseconds": "3682",
          "playername": "xSandin38"
        },
        "1019650373": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "102",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "100.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "60.00",
          "score": "2",
          "skassists": "1",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "8",
          "skpassattempts": "25",
          "skpasses": "20",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "326",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "6",
          "teamId": "500",
          "teamSide": "0",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "NYfrijoles"
        },
        "1857198471": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "75.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "50.00",
          "score": "2",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "12",
          "skpasses": "6",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "-1",
          "skpossession": "152",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "Maesuhn"
        },
        "1915869717": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "172",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "50.00",
          "score": "2",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "100.00",
          "skfow": "2",
          "skgiveaways": "8",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "6",
          "skpassattempts": "12",
          "skpasses": "10",
          "skpasspct": "83.33",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "217",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "Tchuky-7-"
        },
        "1926645693": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "2.90",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.70",
          "glsaves": "7",
          "glshots": "10",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "145",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "30.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "62",
          "toiseconds": "3733",
          "playername": "Rabb1tx14"
        }
      }
    },
    "aggregate": {
      "440740": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 1,
        "glga": 2,
        "glgaa": 1.94,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 1,
        "glsavepct": 0.82,
        "glsaves": 9,
        "glshots": 11,
        "glsoperiods": 0,
        "isGuest": 3,
        "opponentClubId": 2704326,
        "opponentScore": 12,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 996,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 490,
        "ratingOffense": 485,
        "ratingTeamplay": 325,
        "score": 18,
        "skassists": 6,
        "skbs": 4,
        "skdeflections": 0,
        "skfol": 9,
        "skfopct": 130.56,
        "skfow": 13,
        "skgiveaways": 53,
        "skgoals": 3,
        "skgwg": 1,
        "skhits": 4,
        "skinterceptions": 29,
        "skpassattempts": 116,
        "skpasses": 84,
        "skpasspct": 367.71,
        "skpenaltiesdrawn": 1,
        "skpim": 6,
        "skpkclearzone": 4,
        "skplusmin": 5,
        "skpossession": 1140,
        "skppg": 1,
        "sksaucerpasses": 7,
        "skshg": 0,
        "skshotattempts": 17,
        "skshotonnetpct": 290.47,
        "skshotpct": 70,
        "skshots": 11,
        "sktakeaways": 19,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 368,
        "toiseconds": 22148
      },
      "450721": {
        "class": 71,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 2.9,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.7,
        "glsaves": 7,
        "glshots": 10,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 18,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1076,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 485,
        "ratingOffense": 395,
        "ratingTeamplay": 315,
        "score": 12,
        "skassists": 2,
        "skbs": 4,
        "skdeflections": 0,
        "skfol": 13,
        "skfopct": 135,
        "skfow": 9,
        "skgiveaways": 44,
        "skgoals": 2,
        "skgwg": 0,
        "skhits": 20,
        "skinterceptions": 33,
        "skpassattempts": 88,
        "skpasses": 69,
        "skpasspct": 382.37,
        "skpenaltiesdrawn": 2,
        "skpim": 4,
        "skpkclearzone": 2,
        "skplusmin": -5,
        "skpossession": 1045,
        "skppg": 1,
        "sksaucerpasses": 0,
        "skshg": 0,
        "skshotattempts": 16,
        "skshotonnetpct": 307.14,
        "skshotpct": 75,
        "skshots": 9,
        "sktakeaways": 20,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 369,
        "toiseconds": 22227
      }
    }
  },
  {
    "matchId": "6780337000239",
    "timestamp": 1664329937,
    "timeAgo": {
      "number": 2,
      "unit": "days"
    },
    "clubs": {
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "6",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "96",
        "passc": "68",
        "ppg": "3",
        "ppo": "5",
        "result": "1",
        "score": "6",
        "scoreString": "6 - 3",
        "shots": "12",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "296",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "6",
        "goalsAgainst": "3"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "6",
        "gfraw": "3",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "6",
        "opponentTeamArtAbbr": "DROP",
        "passa": "83",
        "passc": "54",
        "ppg": "0",
        "ppo": "3",
        "result": "2",
        "score": "3",
        "scoreString": "3 - 6",
        "shots": "13",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "340",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "6"
      }
    },
    "players": {
      "440740": {
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "70.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "75.00",
          "score": "6",
          "skassists": "3",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "20",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "5",
          "skpassattempts": "30",
          "skpasses": "21",
          "skpasspct": "70.00",
          "skpenaltiesdrawn": "2",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "338",
          "skppg": "0",
          "sksaucerpasses": "5",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "4",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3480",
          "playername": "Sauc---y"
        },
        "655079392": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "1",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "3.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.77",
          "glsaves": "10",
          "glshots": "13",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "30",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "75.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "60.00",
          "score": "6",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "SVNickNitro"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "90.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "35.00",
          "score": "6",
          "skassists": "2",
          "skbs": "3",
          "skdeflections": "1",
          "skfol": "25",
          "skfopct": "30.56",
          "skfow": "11",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "19",
          "skpasses": "11",
          "skpasspct": "57.89",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "139",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "200.00",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BarryWoodsDong"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "203",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "75.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "35.00",
          "score": "6",
          "skassists": "3",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "6",
          "skpassattempts": "24",
          "skpasses": "18",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "1",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "235",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "58",
          "toiseconds": "3478",
          "playername": "Bilal-I96I"
        },
        "1975762195": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "155",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "65.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "55.00",
          "score": "6",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "11",
          "skpasses": "9",
          "skpasspct": "81.82",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "135",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "66.67",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Vaniskov"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "70.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "50.00",
          "score": "6",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "4",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "12",
          "skpasses": "9",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "0",
          "skpossession": "135",
          "skppg": "2",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "9",
          "skshotonnetpct": "44.44",
          "skshotpct": "100.00",
          "skshots": "4",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "A-H-O_20-"
        }
      },
      "450721": {
        "244124053": {
          "class": "4",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "156",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "55.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "85.00",
          "score": "3",
          "skassists": "1",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "8",
          "skfopct": "72.41",
          "skfow": "21",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "8",
          "skinterceptions": "5",
          "skpassattempts": "19",
          "skpasses": "14",
          "skpasspct": "73.68",
          "skpenaltiesdrawn": "0",
          "skpim": "5",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "169",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "55",
          "toiseconds": "3301",
          "playername": "s-zeze-"
        },
        "792179809": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "202",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "75.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "95.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "12",
          "skinterceptions": "6",
          "skpassattempts": "14",
          "skpasses": "8",
          "skpasspct": "57.14",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "0",
          "skpossession": "100",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "33.33",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "xSandin38"
        },
        "1857198471": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "50.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "15.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "11",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "9",
          "skpassattempts": "13",
          "skpasses": "4",
          "skpasspct": "30.77",
          "skpenaltiesdrawn": "2",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "169",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "0.00",
          "skshots": "3",
          "sktakeaways": "5",
          "teamId": "500",
          "teamSide": "0",
          "toi": "57",
          "toiseconds": "3427",
          "playername": "Maesuhn"
        },
        "1915869717": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "172",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "70.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "50.00",
          "score": "3",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "3",
          "skfopct": "57.14",
          "skfow": "4",
          "skgiveaways": "2",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "3",
          "skpassattempts": "9",
          "skpasses": "7",
          "skpasspct": "77.78",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "189",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "66.67",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "59",
          "toiseconds": "3516",
          "playername": "Tchuky-7-"
        },
        "1926645693": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "6",
          "glgaa": "6.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.50",
          "glsaves": "6",
          "glshots": "12",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "145",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "40.00",
          "ratingTeamplay": "45.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Rabb1tx14"
        },
        "1004931773441": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "6",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "243",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "70.00",
          "ratingOffense": "95.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "8",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "11",
          "skpassattempts": "27",
          "skpasses": "19",
          "skpasspct": "70.37",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "0",
          "skpossession": "280",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "1",
          "skshotattempts": "4",
          "skshotonnetpct": "100.00",
          "skshotpct": "50.00",
          "skshots": "4",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Silverstxin"
        }
      }
    },
    "aggregate": {
      "440740": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 1,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 3,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.77,
        "glsaves": 10,
        "glshots": 13,
        "glsoperiods": 0,
        "isGuest": 2,
        "opponentClubId": 2704326,
        "opponentScore": 18,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 779,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 445,
        "ratingOffense": 530,
        "ratingTeamplay": 310,
        "score": 36,
        "skassists": 12,
        "skbs": 4,
        "skdeflections": 1,
        "skfol": 25,
        "skfopct": 30.56,
        "skfow": 11,
        "skgiveaways": 49,
        "skgoals": 6,
        "skgwg": 0,
        "skhits": 6,
        "skinterceptions": 21,
        "skpassattempts": 96,
        "skpasses": 68,
        "skpasspct": 359.71,
        "skpenaltiesdrawn": 3,
        "skpim": 6,
        "skpkclearzone": 1,
        "skplusmin": 0,
        "skpossession": 982,
        "skppg": 3,
        "sksaucerpasses": 8,
        "skshg": 0,
        "skshotattempts": 17,
        "skshotonnetpct": 386.11,
        "skshotpct": 166.67,
        "skshots": 11,
        "sktakeaways": 10,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 356,
        "toiseconds": 21358
      },
      "450721": {
        "class": 71,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 6,
        "glgaa": 6,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.5,
        "glsaves": 6,
        "glshots": 12,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 36,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1217,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 390,
        "ratingOffense": 430,
        "ratingTeamplay": 350,
        "score": 18,
        "skassists": 6,
        "skbs": 5,
        "skdeflections": 0,
        "skfol": 11,
        "skfopct": 129.55,
        "skfow": 25,
        "skgiveaways": 31,
        "skgoals": 3,
        "skgwg": 0,
        "skhits": 24,
        "skinterceptions": 34,
        "skpassattempts": 82,
        "skpasses": 52,
        "skpasspct": 309.74,
        "skpenaltiesdrawn": 3,
        "skpim": 9,
        "skpkclearzone": 2,
        "skplusmin": -2,
        "skpossession": 907,
        "skppg": 0,
        "sksaucerpasses": 2,
        "skshg": 1,
        "skshotattempts": 20,
        "skshotonnetpct": 275,
        "skshotpct": 75,
        "skshots": 12,
        "sktakeaways": 15,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 351,
        "toiseconds": 21044
      }
    }
  },
  {
    "matchId": "6798552740493",
    "timestamp": 1664415775,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "8",
        "gfraw": "1",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "8",
        "opponentTeamArtAbbr": "DROP",
        "passa": "32",
        "passc": "26",
        "ppg": "0",
        "ppo": "1",
        "result": "10",
        "score": "1",
        "scoreString": "1 - 8",
        "shots": "8",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "82",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "1",
        "goalsAgainst": "8"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "1",
        "gfraw": "8",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "1",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "61",
        "passc": "44",
        "ppg": "4",
        "ppo": "6",
        "result": "16385",
        "score": "8",
        "scoreString": "8 - 1",
        "shots": "13",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "307",
        "winnerByDnf": "1",
        "winnerByGoalieDnf": "1",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "8",
        "goalsAgainst": "1"
      }
    },
    "players": {
      "440740": {
        "261807399": {
          "class": "6",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "8",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "196",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "50.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "15.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "2",
          "skpassattempts": "6",
          "skpasses": "4",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "-3",
          "skpossession": "73",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "1",
          "skshotattempts": "4",
          "skshotonnetpct": "100.00",
          "skshotpct": "25.00",
          "skshots": "4",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "32",
          "toiseconds": "1930",
          "playername": "BingBongByron69"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "8",
          "glgaa": "14.55",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.43",
          "glsaves": "6",
          "glshots": "14",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "8",
          "opponentTeamId": "501",
          "player_dnf": "1",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "10.00",
          "ratingTeamplay": "45.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "33",
          "toiseconds": "1963",
          "playername": "Seagull_3134"
        },
        "1138007442": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "8",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "209",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "45.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "35.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "2",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "4",
          "skpasses": "3",
          "skpasspct": "75.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "43",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "29",
          "toiseconds": "1749",
          "playername": "Col4Bin420"
        },
        "1716696597": {
          "class": "14",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "8",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "39",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "25.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "25.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "2",
          "skpassattempts": "2",
          "skpasses": "2",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "0",
          "skpim": "4",
          "skpkclearzone": "0",
          "skplusmin": "-3",
          "skpossession": "32",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "30",
          "toiseconds": "1783",
          "playername": "KDogRacing29"
        },
        "1004204818872": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "8",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "141",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "50.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "70.00",
          "score": "1",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "8",
          "skpassattempts": "11",
          "skpasses": "9",
          "skpasspct": "81.82",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "3",
          "skplusmin": "-3",
          "skpossession": "111",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "7",
          "teamId": "500",
          "teamSide": "0",
          "toi": "33",
          "toiseconds": "1963",
          "playername": "mattybeastmode12"
        },
        "1004704581653": {
          "class": "5",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "8",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "261",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "55.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "85.00",
          "score": "1",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "13",
          "skfopct": "35.00",
          "skfow": "7",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "12",
          "skinterceptions": "2",
          "skpassattempts": "7",
          "skpasses": "7",
          "skpasspct": "100.00",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-4",
          "skpossession": "91",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "31",
          "toiseconds": "1883",
          "playername": "xBxllxgxrde-"
        }
      },
      "450721": {
        "178617183": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "1",
          "glgaa": "1.82",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.88",
          "glsaves": "7",
          "glshots": "8",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "132",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "55.00",
          "score": "8",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "33",
          "toiseconds": "1963",
          "playername": "falcolover"
        },
        "1248857979": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "248",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "55.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "15.00",
          "score": "8",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "15",
          "skpasses": "12",
          "skpasspct": "80.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "187",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "33",
          "toiseconds": "1963",
          "playername": "YuNgWuN413"
        },
        "1695286344": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "208",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "55.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "0.00",
          "score": "8",
          "skassists": "4",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "3",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "13",
          "skpasses": "9",
          "skpasspct": "69.23",
          "skpenaltiesdrawn": "2",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "116",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "80.00",
          "skshotpct": "75.00",
          "skshots": "4",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "31",
          "toiseconds": "1843",
          "playername": "Lambert--22"
        },
        "1754078827": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "284",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "65.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "80.00",
          "score": "8",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "7",
          "skfopct": "68.18",
          "skfow": "15",
          "skgiveaways": "4",
          "skgoals": "3",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "2",
          "skpassattempts": "11",
          "skpasses": "9",
          "skpasspct": "81.82",
          "skpenaltiesdrawn": "2",
          "skpim": "0",
          "skpkclearzone": "1",
          "skplusmin": "3",
          "skpossession": "111",
          "skppg": "3",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "100.00",
          "skshots": "3",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "33",
          "toiseconds": "1963",
          "playername": "Tekk112"
        },
        "1839064560": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "50.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "30.00",
          "score": "8",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "7",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "3",
          "skpassattempts": "8",
          "skpasses": "4",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "119",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "33",
          "toiseconds": "1963",
          "playername": "Buck_PmT"
        },
        "1857603419": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "1",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "60.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "60.00",
          "score": "8",
          "skassists": "4",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "8",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "2",
          "skpassattempts": "13",
          "skpasses": "9",
          "skpasspct": "69.23",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "3",
          "skpossession": "148",
          "skppg": "1",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "85.71",
          "skshotpct": "33.33",
          "skshots": "6",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "33",
          "toiseconds": "1963",
          "playername": "Letang--"
        }
      }
    },
    "aggregate": {
      "440740": {
        "class": 76,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 8,
        "glgaa": 14.55,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.43,
        "glsaves": 6,
        "glshots": 14,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2704326,
        "opponentScore": 48,
        "opponentTeamId": 3006,
        "player_dnf": 1,
        "playerLevel": 1034,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 285,
        "ratingOffense": 305,
        "ratingTeamplay": 275,
        "score": 6,
        "skassists": 1,
        "skbs": 0,
        "skdeflections": 0,
        "skfol": 15,
        "skfopct": 35,
        "skfow": 7,
        "skgiveaways": 16,
        "skgoals": 1,
        "skgwg": 0,
        "skhits": 23,
        "skinterceptions": 15,
        "skpassattempts": 30,
        "skpasses": 25,
        "skpasspct": 423.49,
        "skpenaltiesdrawn": 1,
        "skpim": 10,
        "skpkclearzone": 4,
        "skplusmin": -16,
        "skpossession": 350,
        "skppg": 0,
        "sksaucerpasses": 0,
        "skshg": 1,
        "skshotattempts": 9,
        "skshotonnetpct": 266.67,
        "skshotpct": 25,
        "skshots": 7,
        "sktakeaways": 14,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 188,
        "toiseconds": 11271
      },
      "450721": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 1,
        "glgaa": 1.82,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.88,
        "glsaves": 7,
        "glshots": 8,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 6,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1359,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 345,
        "ratingOffense": 505,
        "ratingTeamplay": 240,
        "score": 48,
        "skassists": 12,
        "skbs": 2,
        "skdeflections": 0,
        "skfol": 7,
        "skfopct": 68.18,
        "skfow": 15,
        "skgiveaways": 29,
        "skgoals": 8,
        "skgwg": 0,
        "skhits": 4,
        "skinterceptions": 11,
        "skpassattempts": 60,
        "skpasses": 43,
        "skpasspct": 350.28,
        "skpenaltiesdrawn": 5,
        "skpim": 2,
        "skpkclearzone": 1,
        "skplusmin": 15,
        "skpossession": 681,
        "skppg": 4,
        "sksaucerpasses": 3,
        "skshg": 0,
        "skshotattempts": 16,
        "skshotonnetpct": 240.71,
        "skshotpct": 208.33,
        "skshots": 13,
        "sktakeaways": 7,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 196,
        "toiseconds": 11658
      }
    }
  },
  {
    "matchId": "6798961480082",
    "timestamp": 1664499822,
    "timeAgo": {
      "number": 1,
      "unit": "hours"
    },
    "clubs": {
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "5",
        "gfraw": "7",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "5",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "99",
        "passc": "68",
        "ppg": "2",
        "ppo": "2",
        "result": "1",
        "score": "7",
        "scoreString": "7 - 5",
        "shots": "16",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "311",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "7",
        "goalsAgainst": "5"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "7",
        "gfraw": "5",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "7",
        "opponentTeamArtAbbr": "DROP",
        "passa": "100",
        "passc": "62",
        "ppg": "2",
        "ppo": "2",
        "result": "2",
        "score": "5",
        "scoreString": "5 - 7",
        "shots": "16",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "418",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "5",
        "goalsAgainst": "7"
      }
    },
    "players": {
      "440740": {
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "5",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "90.00",
          "ratingOffense": "80.00",
          "ratingTeamplay": "65.00",
          "score": "7",
          "skassists": "2",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "5",
          "skinterceptions": "10",
          "skpassattempts": "25",
          "skpasses": "18",
          "skpasspct": "72.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "173",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "2",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3593",
          "playername": "Sauc---y"
        },
        "655079392": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "1",
          "glga": "5",
          "glgaa": "5.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.69",
          "glsaves": "11",
          "glshots": "16",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "5",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "30",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "60.00",
          "score": "7",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "SVNickNitro"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "5",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "75.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "80.00",
          "score": "7",
          "skassists": "3",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "14",
          "skfopct": "53.33",
          "skfow": "16",
          "skgiveaways": "13",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "5",
          "skpassattempts": "22",
          "skpasses": "14",
          "skpasspct": "63.64",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "226",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "BarryWoodsDong"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "5",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "203",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "60.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "75.00",
          "score": "7",
          "skassists": "0",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "6",
          "skinterceptions": "9",
          "skpassattempts": "17",
          "skpasses": "9",
          "skpasspct": "52.94",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "185",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Bilal-I96I"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "5",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "55.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "65.00",
          "score": "7",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "4",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "7",
          "skpassattempts": "11",
          "skpasses": "8",
          "skpasspct": "72.73",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "137",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "85.71",
          "skshotpct": "66.67",
          "skshots": "6",
          "sktakeaways": "5",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "A-H-O_20-"
        },
        "1004704581653": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "5",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "261",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "60.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "45.00",
          "score": "7",
          "skassists": "3",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "19",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "7",
          "skpassattempts": "24",
          "skpasses": "19",
          "skpasspct": "79.17",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "2",
          "skpossession": "249",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "75.00",
          "skshotpct": "66.67",
          "skshots": "3",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "60",
          "toiseconds": "3574",
          "playername": "xBxllxgxrde-"
        }
      },
      "450721": {
        "1248857979": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "7",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "248",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "60.00",
          "ratingOffense": "70.00",
          "ratingTeamplay": "55.00",
          "score": "5",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "17",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "7",
          "skinterceptions": "8",
          "skpassattempts": "22",
          "skpasses": "11",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "193",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "58",
          "toiseconds": "3497",
          "playername": "YuNgWuN413"
        },
        "1677503527": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "7",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "107",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "55.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "25.00",
          "score": "5",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "9",
          "skgoals": "2",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "6",
          "skpassattempts": "19",
          "skpasses": "13",
          "skpasspct": "68.42",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "172",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "4",
          "skshotonnetpct": "50.00",
          "skshotpct": "100.00",
          "skshots": "2",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3594",
          "playername": "Toeffoli"
        },
        "1754078827": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "7",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "284",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "45.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "75.00",
          "score": "5",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "16",
          "skfopct": "46.67",
          "skfow": "14",
          "skgiveaways": "8",
          "skgoals": "3",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "6",
          "skpassattempts": "16",
          "skpasses": "11",
          "skpasspct": "68.75",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "252",
          "skppg": "1",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "100.00",
          "skshotpct": "100.00",
          "skshots": "3",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Tekk112"
        },
        "1857603419": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "7",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "50.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "60.00",
          "score": "5",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "9",
          "skpassattempts": "16",
          "skpasses": "10",
          "skpasspct": "62.50",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "-2",
          "skpossession": "168",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Letang--"
        },
        "1926645693": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "1",
          "gldsaves": "0",
          "glga": "7",
          "glgaa": "7.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.56",
          "glsaves": "9",
          "glshots": "16",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "7",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "146",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "35.00",
          "ratingTeamplay": "40.00",
          "score": "5",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "Rabb1tx14"
        },
        "1952356637": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "7",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "293",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "50.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "75.00",
          "score": "5",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "6",
          "skpassattempts": "24",
          "skpasses": "15",
          "skpasspct": "62.50",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-2",
          "skpossession": "267",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "4",
          "sktakeaways": "4",
          "teamId": "500",
          "teamSide": "0",
          "toi": "60",
          "toiseconds": "3600",
          "playername": "XxCR33_SniPeRxX"
        }
      }
    },
    "aggregate": {
      "440740": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 1,
        "glga": 5,
        "glgaa": 5,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.69,
        "glsaves": 11,
        "glshots": 16,
        "glsoperiods": 0,
        "isGuest": 3,
        "opponentClubId": 2704326,
        "opponentScore": 30,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 885,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 410,
        "ratingOffense": 490,
        "ratingTeamplay": 390,
        "score": 42,
        "skassists": 9,
        "skbs": 2,
        "skdeflections": 0,
        "skfol": 14,
        "skfopct": 53.33,
        "skfow": 16,
        "skgiveaways": 54,
        "skgoals": 7,
        "skgwg": 0,
        "skhits": 16,
        "skinterceptions": 38,
        "skpassattempts": 99,
        "skpasses": 68,
        "skpasspct": 340.48,
        "skpenaltiesdrawn": 3,
        "skpim": 4,
        "skpkclearzone": 0,
        "skplusmin": 10,
        "skpossession": 970,
        "skppg": 2,
        "sksaucerpasses": 3,
        "skshg": 0,
        "skshotattempts": 17,
        "skshotonnetpct": 377.38,
        "skshotpct": 183.34,
        "skshots": 13,
        "sktakeaways": 16,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 360,
        "toiseconds": 21567
      },
      "450721": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 1,
        "gldsaves": 0,
        "glga": 7,
        "glgaa": 7,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.56,
        "glsaves": 9,
        "glshots": 16,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 42,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1377,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 330,
        "ratingOffense": 465,
        "ratingTeamplay": 330,
        "score": 30,
        "skassists": 6,
        "skbs": 1,
        "skdeflections": 0,
        "skfol": 16,
        "skfopct": 46.67,
        "skfow": 14,
        "skgiveaways": 52,
        "skgoals": 5,
        "skgwg": 0,
        "skhits": 15,
        "skinterceptions": 35,
        "skpassattempts": 97,
        "skpasses": 60,
        "skpasspct": 312.17,
        "skpenaltiesdrawn": 2,
        "skpim": 6,
        "skpkclearzone": 1,
        "skplusmin": -10,
        "skpossession": 1052,
        "skppg": 2,
        "sksaucerpasses": 5,
        "skshg": 0,
        "skshotattempts": 14,
        "skshotonnetpct": 216.67,
        "skshotpct": 200,
        "skshots": 9,
        "sktakeaways": 19,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 358,
        "toiseconds": 21491
      }
    }
  },
  {
    "matchId": "6802498170387",
    "timestamp": 1664413678,
    "timeAgo": {
      "number": 1,
      "unit": "days"
    },
    "clubs": {
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "3",
        "gfraw": "2",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "3",
        "opponentTeamArtAbbr": "DROP",
        "passa": "75",
        "passc": "47",
        "ppg": "0",
        "ppo": "0",
        "result": "6",
        "score": "2",
        "scoreString": "2 - 3",
        "shots": "6",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "161",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "2",
        "goalsAgainst": "3"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "2",
        "gfraw": "3",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "2",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "141",
        "passc": "98",
        "ppg": "1",
        "ppo": "4",
        "result": "5",
        "score": "3",
        "scoreString": "3 - 2",
        "shots": "16",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "652",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "3",
        "goalsAgainst": "2"
      }
    },
    "players": {
      "440740": {
        "261807399": {
          "class": "6",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "196",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "65.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "40.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "11",
          "skpasses": "6",
          "skpasspct": "54.55",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "1",
          "skpossession": "106",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "50.00",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "BingBongByron69"
        },
        "385985151": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "82",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "100.00",
          "ratingOffense": "65.00",
          "ratingTeamplay": "50.00",
          "score": "2",
          "skassists": "0",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "10",
          "skpassattempts": "17",
          "skpasses": "12",
          "skpasspct": "70.59",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "248",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "6",
          "teamId": "500",
          "teamSide": "0",
          "toi": "61",
          "toiseconds": "3684",
          "playername": "Sauc---y"
        },
        "880469871": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "3",
          "glgaa": "2.86",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "1",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.81",
          "glsaves": "13",
          "glshots": "16",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "80.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "45.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "Seagull_3134"
        },
        "1138007442": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "209",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "70.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "45.00",
          "score": "2",
          "skassists": "1",
          "skbs": "1",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "10",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "4",
          "skpassattempts": "14",
          "skpasses": "6",
          "skpasspct": "42.86",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "150",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "100.00",
          "skshots": "1",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "59",
          "toiseconds": "3565",
          "playername": "Col4Bin420"
        },
        "1867569276": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "208",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "100.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "40.00",
          "score": "2",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "7",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "7",
          "skpassattempts": "18",
          "skpasses": "15",
          "skpasspct": "83.33",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "2",
          "skplusmin": "1",
          "skpossession": "191",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "2",
          "teamId": "500",
          "teamSide": "0",
          "toi": "63",
          "toiseconds": "3798",
          "playername": "xVerci-"
        },
        "1004704581653": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "3",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "261",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "100.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "60.00",
          "score": "2",
          "skassists": "1",
          "skbs": "2",
          "skdeflections": "0",
          "skfol": "10",
          "skfopct": "60.00",
          "skfow": "15",
          "skgiveaways": "13",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "18",
          "skpassattempts": "14",
          "skpasses": "7",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "1",
          "skpim": "2",
          "skpkclearzone": "1",
          "skplusmin": "1",
          "skpossession": "169",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "9",
          "teamId": "500",
          "teamSide": "0",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "xBxllxgxrde-"
        }
      },
      "450721": {
        "178617183": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "2",
          "glgaa": "1.90",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.67",
          "glsaves": "4",
          "glshots": "6",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "132",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "70.00",
          "ratingOffense": "40.00",
          "ratingTeamplay": "60.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "63",
          "toiseconds": "3800",
          "playername": "falcolover"
        },
        "1248857979": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "248",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "75.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "2",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "11",
          "skpassattempts": "28",
          "skpasses": "17",
          "skpasspct": "60.71",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "298",
          "skppg": "0",
          "sksaucerpasses": "3",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "5",
          "teamId": "501",
          "teamSide": "1",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "YuNgWuN413"
        },
        "1677503527": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "107",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "60.00",
          "ratingOffense": "90.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "7",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "31",
          "skpasses": "20",
          "skpasspct": "64.52",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "196",
          "skppg": "1",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "7",
          "skshotonnetpct": "42.86",
          "skshotpct": "33.33",
          "skshots": "3",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "Toeffoli"
        },
        "1754078827": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "284",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "70.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "80.00",
          "score": "3",
          "skassists": "1",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "15",
          "skfopct": "40.00",
          "skfow": "10",
          "skgiveaways": "14",
          "skgoals": "2",
          "skgwg": "1",
          "skhits": "2",
          "skinterceptions": "7",
          "skpassattempts": "30",
          "skpasses": "17",
          "skpasspct": "56.67",
          "skpenaltiesdrawn": "2",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "300",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "6",
          "skshotonnetpct": "100.00",
          "skshotpct": "33.33",
          "skshots": "6",
          "sktakeaways": "5",
          "teamId": "501",
          "teamSide": "1",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "Tekk112"
        },
        "1839064560": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "188",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "65.00",
          "ratingOffense": "60.00",
          "ratingTeamplay": "65.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "14",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "8",
          "skpassattempts": "17",
          "skpasses": "14",
          "skpasspct": "82.35",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "140",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "4",
          "teamId": "501",
          "teamSide": "1",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "Buck_PmT"
        },
        "1857603419": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "2",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "70.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "70.00",
          "score": "3",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "11",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "7",
          "skpassattempts": "35",
          "skpasses": "30",
          "skpasspct": "85.71",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-1",
          "skpossession": "334",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "40.00",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "5",
          "teamId": "501",
          "teamSide": "1",
          "toi": "63",
          "toiseconds": "3803",
          "playername": "Letang--"
        }
      }
    },
    "aggregate": {
      "440740": {
        "class": 60,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 3,
        "glgaa": 2.86,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 1,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.81,
        "glsaves": 13,
        "glshots": 16,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2704326,
        "opponentScore": 18,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1144,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 515,
        "ratingOffense": 380,
        "ratingTeamplay": 280,
        "score": 12,
        "skassists": 2,
        "skbs": 5,
        "skdeflections": 0,
        "skfol": 10,
        "skfopct": 60,
        "skfow": 15,
        "skgiveaways": 54,
        "skgoals": 2,
        "skgwg": 0,
        "skhits": 3,
        "skinterceptions": 41,
        "skpassattempts": 74,
        "skpasses": 46,
        "skpasspct": 301.33,
        "skpenaltiesdrawn": 1,
        "skpim": 8,
        "skpkclearzone": 5,
        "skplusmin": 5,
        "skpossession": 864,
        "skppg": 0,
        "sksaucerpasses": 4,
        "skshg": 0,
        "skshotattempts": 8,
        "skshotonnetpct": 416.67,
        "skshotpct": 200,
        "skshots": 6,
        "sktakeaways": 19,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 372,
        "toiseconds": 22456
      },
      "450721": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 2,
        "glgaa": 1.9,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.67,
        "glsaves": 4,
        "glshots": 6,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 12,
        "opponentTeamId": 3000,
        "player_dnf": 0,
        "playerLevel": 1258,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 410,
        "ratingOffense": 440,
        "ratingTeamplay": 405,
        "score": 18,
        "skassists": 4,
        "skbs": 0,
        "skdeflections": 0,
        "skfol": 15,
        "skfopct": 40,
        "skfow": 10,
        "skgiveaways": 60,
        "skgoals": 3,
        "skgwg": 1,
        "skhits": 8,
        "skinterceptions": 34,
        "skpassattempts": 141,
        "skpasses": 98,
        "skpasspct": 349.96,
        "skpenaltiesdrawn": 4,
        "skpim": 2,
        "skpkclearzone": 0,
        "skplusmin": -5,
        "skpossession": 1268,
        "skppg": 1,
        "sksaucerpasses": 5,
        "skshg": 0,
        "skshotattempts": 22,
        "skshotonnetpct": 349.53,
        "skshotpct": 66.66,
        "skshots": 14,
        "sktakeaways": 20,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 378,
        "toiseconds": 22815
      }
    }
  },
  {
    "matchId": "6812326280031",
    "timestamp": 1664503952,
    "timeAgo": {
      "number": 9,
      "unit": "minutes"
    },
    "clubs": {
      "440740": {
        "clubDivision": "1",
        "cNhlOnlineGameType": "5",
        "garaw": "9",
        "gfraw": "0",
        "losses": "1",
        "memberString": "6 / 6",
        "opponentClubId": "450721",
        "opponentScore": "9",
        "opponentTeamArtAbbr": "EASHL",
        "passa": "26",
        "passc": "16",
        "ppg": "0",
        "ppo": "0",
        "result": "10",
        "score": "0",
        "scoreString": "0 - 9",
        "shots": "3",
        "teamArtAbbr": "DROP",
        "teamSide": "1",
        "toa": "71",
        "winnerByDnf": "0",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Electrified",
          "clubId": 440740,
          "regionId": 5,
          "teamId": 5070,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "183",
            "useBaseAsset": "1"
          }
        },
        "goals": "0",
        "goalsAgainst": "9"
      },
      "450721": {
        "clubDivision": "3",
        "cNhlOnlineGameType": "5",
        "garaw": "0",
        "gfraw": "9",
        "losses": "0",
        "memberString": "6 / 6",
        "opponentClubId": "440740",
        "opponentScore": "0",
        "opponentTeamArtAbbr": "DROP",
        "passa": "62",
        "passc": "41",
        "ppg": "2",
        "ppo": "4",
        "result": "16385",
        "score": "9",
        "scoreString": "9 - 0",
        "shots": "17",
        "teamArtAbbr": "EASHL",
        "teamSide": "0",
        "toa": "276",
        "winnerByDnf": "1",
        "winnerByGoalieDnf": "0",
        "details": {
          "name": "M6 Troublemakers",
          "clubId": 450721,
          "regionId": 5,
          "teamId": 5137,
          "customKit": {
            "isCustomTeam": "1",
            "crestAssetId": "242",
            "useBaseAsset": "1"
          }
        },
        "goals": "9",
        "goalsAgainst": "0"
      }
    },
    "players": {
      "440740": {
        "655079392": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "9",
          "glgaa": "18.62",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.47",
          "glsaves": "8",
          "glshots": "17",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "9",
          "opponentTeamId": "500",
          "player_dnf": "0",
          "playerLevel": "30",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "65.00",
          "ratingOffense": "25.00",
          "ratingTeamplay": "50.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "501",
          "teamSide": "1",
          "toi": "29",
          "toiseconds": "1755",
          "playername": "SVNickNitro"
        },
        "787537987": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "9",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "10",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "25.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "70.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "10",
          "skfopct": "50.00",
          "skfow": "10",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "3",
          "skpassattempts": "5",
          "skpasses": "3",
          "skpasspct": "60.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-5",
          "skpossession": "43",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "1",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "24",
          "toiseconds": "1420",
          "playername": "BarryWoodsDong"
        },
        "1012193622": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "450721",
          "opponentScore": "9",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "203",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "30.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "45.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "3",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "4",
          "skinterceptions": "4",
          "skpassattempts": "4",
          "skpasses": "2",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "0",
          "skpim": "2",
          "skpkclearzone": "3",
          "skplusmin": "-6",
          "skpossession": "41",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "23",
          "toiseconds": "1381",
          "playername": "Bilal-I96I"
        },
        "1135212727": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "9",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "164",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "15.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "20.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "2",
          "skpassattempts": "2",
          "skpasses": "1",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "0",
          "skpim": "4",
          "skpkclearzone": "1",
          "skplusmin": "-6",
          "skpossession": "35",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "22",
          "toiseconds": "1300",
          "playername": "Mr_Janack12"
        },
        "1003797820592": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "9",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "30.00",
          "ratingOffense": "50.00",
          "ratingTeamplay": "5.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "6",
          "skpasses": "4",
          "skpasspct": "66.67",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "-7",
          "skpossession": "43",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "1",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "501",
          "teamSide": "1",
          "toi": "29",
          "toiseconds": "1755",
          "playername": "A-H-O_20-"
        },
        "1004704581653": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "450721",
          "opponentScore": "9",
          "opponentTeamId": "500",
          "player_dnf": "1",
          "playerLevel": "261",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "40.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "40.00",
          "score": "0",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "2",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "5",
          "skpassattempts": "6",
          "skpasses": "3",
          "skpasspct": "50.00",
          "skpenaltiesdrawn": "1",
          "skpim": "12",
          "skpkclearzone": "1",
          "skplusmin": "-6",
          "skpossession": "55",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "100.00",
          "skshotpct": "0.00",
          "skshots": "2",
          "sktakeaways": "3",
          "teamId": "501",
          "teamSide": "1",
          "toi": "25",
          "toiseconds": "1484",
          "playername": "xBxllxgxrde-"
        }
      },
      "450721": {
        "178617183": {
          "class": "21",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "1.00",
          "glsaves": "3",
          "glshots": "3",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "132",
          "pNhlOnlineGameType": "5",
          "position": "goalie",
          "posSorted": "0",
          "ratingDefense": "60.00",
          "ratingOffense": "55.00",
          "ratingTeamplay": "50.00",
          "score": "9",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "0",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "0",
          "skpassattempts": "0",
          "skpasses": "0",
          "skpasspct": "0.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "0",
          "skpossession": "0",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "0",
          "teamId": "500",
          "teamSide": "0",
          "toi": "29",
          "toiseconds": "1755",
          "playername": "falcolover"
        },
        "1248857979": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "248",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "2",
          "ratingDefense": "65.00",
          "ratingOffense": "85.00",
          "ratingTeamplay": "55.00",
          "score": "9",
          "skassists": "3",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "12",
          "skgoals": "0",
          "skgwg": "0",
          "skhits": "2",
          "skinterceptions": "4",
          "skpassattempts": "21",
          "skpasses": "13",
          "skpasspct": "61.90",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "7",
          "skpossession": "208",
          "skppg": "0",
          "sksaucerpasses": "2",
          "skshg": "0",
          "skshotattempts": "0",
          "skshotonnetpct": "0.00",
          "skshotpct": "0.00",
          "skshots": "0",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "29",
          "toiseconds": "1755",
          "playername": "YuNgWuN413"
        },
        "1695286344": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "208",
          "pNhlOnlineGameType": "5",
          "position": "leftWing",
          "posSorted": "4",
          "ratingDefense": "55.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "60.00",
          "score": "9",
          "skassists": "3",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "5",
          "skgoals": "3",
          "skgwg": "0",
          "skhits": "1",
          "skinterceptions": "1",
          "skpassattempts": "17",
          "skpasses": "12",
          "skpasspct": "70.59",
          "skpenaltiesdrawn": "1",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "7",
          "skpossession": "123",
          "skppg": "2",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "5",
          "skshotonnetpct": "100.00",
          "skshotpct": "60.00",
          "skshots": "5",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "29",
          "toiseconds": "1755",
          "playername": "Lambert--22"
        },
        "1754078827": {
          "class": "1",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "0",
          "opponentClubId": "440740",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "284",
          "pNhlOnlineGameType": "5",
          "position": "center",
          "posSorted": "5",
          "ratingDefense": "65.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "75.00",
          "score": "9",
          "skassists": "4",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "11",
          "skfopct": "56.00",
          "skfow": "14",
          "skgiveaways": "1",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "3",
          "skpassattempts": "10",
          "skpasses": "7",
          "skpasspct": "70.00",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "7",
          "skpossession": "105",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "3",
          "skshotonnetpct": "66.67",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "29",
          "toiseconds": "1755",
          "playername": "Tekk112"
        },
        "1857603419": {
          "class": "15",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "299",
          "pNhlOnlineGameType": "5",
          "position": "defenseMen",
          "posSorted": "1",
          "ratingDefense": "60.00",
          "ratingOffense": "75.00",
          "ratingTeamplay": "55.00",
          "score": "9",
          "skassists": "0",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "6",
          "skgoals": "1",
          "skgwg": "0",
          "skhits": "0",
          "skinterceptions": "1",
          "skpassattempts": "7",
          "skpasses": "4",
          "skpasspct": "57.14",
          "skpenaltiesdrawn": "0",
          "skpim": "0",
          "skpkclearzone": "0",
          "skplusmin": "7",
          "skpossession": "149",
          "skppg": "0",
          "sksaucerpasses": "0",
          "skshg": "0",
          "skshotattempts": "2",
          "skshotonnetpct": "100.00",
          "skshotpct": "50.00",
          "skshots": "2",
          "sktakeaways": "1",
          "teamId": "500",
          "teamSide": "0",
          "toi": "29",
          "toiseconds": "1755",
          "playername": "Letang--"
        },
        "1952356637": {
          "class": "2",
          "glbrksavepct": "0.00",
          "glbrksaves": "0",
          "glbrkshots": "0",
          "gldsaves": "0",
          "glga": "0",
          "glgaa": "0.00",
          "glpensavepct": "0.00",
          "glpensaves": "0",
          "glpenshots": "0",
          "glpkclearzone": "0",
          "glpokechecks": "0",
          "glsavepct": "0.00",
          "glsaves": "0",
          "glshots": "0",
          "glsoperiods": "0",
          "isGuest": "1",
          "opponentClubId": "440740",
          "opponentScore": "0",
          "opponentTeamId": "501",
          "player_dnf": "0",
          "playerLevel": "293",
          "pNhlOnlineGameType": "5",
          "position": "rightWing",
          "posSorted": "3",
          "ratingDefense": "60.00",
          "ratingOffense": "100.00",
          "ratingTeamplay": "85.00",
          "score": "9",
          "skassists": "3",
          "skbs": "0",
          "skdeflections": "0",
          "skfol": "0",
          "skfopct": "0.00",
          "skfow": "0",
          "skgiveaways": "4",
          "skgoals": "4",
          "skgwg": "0",
          "skhits": "3",
          "skinterceptions": "3",
          "skpassattempts": "7",
          "skpasses": "5",
          "skpasspct": "71.43",
          "skpenaltiesdrawn": "2",
          "skpim": "10",
          "skpkclearzone": "0",
          "skplusmin": "6",
          "skpossession": "117",
          "skppg": "0",
          "sksaucerpasses": "1",
          "skshg": "0",
          "skshotattempts": "8",
          "skshotonnetpct": "75.00",
          "skshotpct": "66.67",
          "skshots": "6",
          "sktakeaways": "3",
          "teamId": "500",
          "teamSide": "0",
          "toi": "27",
          "toiseconds": "1603",
          "playername": "XxCR33_SniPeRxX"
        }
      }
    },
    "aggregate": {
      "440740": {
        "class": 69,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 9,
        "glgaa": 18.62,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 0.47,
        "glsaves": 8,
        "glshots": 17,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2704326,
        "opponentScore": 54,
        "opponentTeamId": 3000,
        "player_dnf": 5,
        "playerLevel": 967,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 205,
        "ratingOffense": 285,
        "ratingTeamplay": 230,
        "score": 0,
        "skassists": 0,
        "skbs": 0,
        "skdeflections": 0,
        "skfol": 10,
        "skfopct": 50,
        "skfow": 10,
        "skgiveaways": 17,
        "skgoals": 0,
        "skgwg": 0,
        "skhits": 8,
        "skinterceptions": 17,
        "skpassattempts": 23,
        "skpasses": 13,
        "skpasspct": 276.67,
        "skpenaltiesdrawn": 1,
        "skpim": 18,
        "skpkclearzone": 5,
        "skplusmin": -30,
        "skpossession": 217,
        "skppg": 0,
        "sksaucerpasses": 1,
        "skshg": 0,
        "skshotattempts": 4,
        "skshotonnetpct": 200,
        "skshotpct": 0,
        "skshots": 3,
        "sktakeaways": 9,
        "teamId": 3006,
        "teamSide": 6,
        "toi": 152,
        "toiseconds": 9095
      },
      "450721": {
        "class": 56,
        "glbrksavepct": 0,
        "glbrksaves": 0,
        "glbrkshots": 0,
        "gldsaves": 0,
        "glga": 0,
        "glgaa": 0,
        "glpensavepct": 0,
        "glpensaves": 0,
        "glpenshots": 0,
        "glpkclearzone": 0,
        "glpokechecks": 0,
        "glsavepct": 1,
        "glsaves": 3,
        "glshots": 3,
        "glsoperiods": 0,
        "isGuest": 4,
        "opponentClubId": 2644440,
        "opponentScore": 0,
        "opponentTeamId": 3006,
        "player_dnf": 0,
        "playerLevel": 1464,
        "pNhlOnlineGameType": 30,
        "position": 0,
        "posSorted": 15,
        "ratingDefense": 365,
        "ratingOffense": 515,
        "ratingTeamplay": 380,
        "score": 54,
        "skassists": 13,
        "skbs": 0,
        "skdeflections": 0,
        "skfol": 11,
        "skfopct": 56,
        "skfow": 14,
        "skgiveaways": 28,
        "skgoals": 9,
        "skgwg": 0,
        "skhits": 6,
        "skinterceptions": 12,
        "skpassattempts": 62,
        "skpasses": 41,
        "skpasspct": 331.06,
        "skpenaltiesdrawn": 4,
        "skpim": 10,
        "skpkclearzone": 0,
        "skplusmin": 34,
        "skpossession": 702,
        "skppg": 2,
        "sksaucerpasses": 4,
        "skshg": 0,
        "skshotattempts": 18,
        "skshotonnetpct": 341.67,
        "skshotpct": 226.67,
        "skshots": 15,
        "sktakeaways": 9,
        "teamId": 3000,
        "teamSide": 0,
        "toi": 172,
        "toiseconds": 10378
      }
    }
  }
]